import { Document, Paragraph, TextRun, AlignmentType, Footer } from "docx";

export class LawDiskDocumentCreator {
  create(data) {
    const { summary, transcript, date, tags, isSummaryOnly } = data; // Voeg 'isSummaryOnly' toe aan de data object
    const document = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: "Montserrat", 
            },
          },
        },
      },
      sections: [
        {
          children: [
            // Title: "LawDisk Notulen"
            new Paragraph({
              children: [
                new TextRun({
                  text: "LawDisk Notulen",
                  font: "Montserrat",
                  bold: true,
                  size: 48, // 24 points in Word
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: { after: 100 }, // Add some spacing after the title
            }),
            // Subheading: "Van de vergadering op [date and time]"
            new Paragraph({
              children: [
                new TextRun({
                  text: `Van de vergadering op ${date}`,
                  font: "Montserrat",
                  size: 24, // 12 points in Word
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: { after: 200 }, // Spacing before the tags
            }),
            // Tags at the top
            this.createHeading("Tags"),
            new Paragraph({
              children: [
                new TextRun({
                  text: tags.join(", "),
                  font: "Montserrat",
                  size: 24, // Font size for the tags
                }),
              ],
              spacing: { after: 200 }, // Add some space after the tags
            }),
            // Samenvatting section
            this.createHeading("Samenvatting"),
            new Paragraph({
              children: [
                new TextRun({
                  text: summary,
                  font: "Montserrat",
                  size: 24, // 12 points in Word
                }),
              ],
              spacing: { after: 200 }, // Add spacing after the summary
            }),
            // Voeg transcriptie-sectie alleen toe als isSummaryOnly false is
            ...(isSummaryOnly ? [] : [
              this.createHeading("Transcriptie"),
              new Paragraph({
                children: [
                  new TextRun({
                    text: transcript,
                    font: "Montserrat",
                    size: 24, // Font size for the transcript
                  }),
                ],
                spacing: { after: 200 }, // Add spacing after the transcript
              }),
            ]),
          ],
        },
      ],
    });

    return document;
  }

  // Helper function to create the heading
  createHeading(text) {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          font: "Montserrat",
          bold: true,
          size: 36, // 18 points in Word
        }),
      ],
      spacing: { before: 200, after: 200 }, // Spacing before and after the heading
    });
  }
}
