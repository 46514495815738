// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Checkbox,
  InputRightElement,
  InputGroup,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { VSeparator } from "components/Separator/Separator";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// Assets
import { FaEye, FaEyeSlash } from "react-icons/fa";
import cover from "assets/img/cover-auth.png";
import React, { useRef, useState, useEffect } from "react";
import {
  signIn,
  RespondToMfaChallenge,
  RespondToNewPasswordChallenge,
  RespondToMfaSetupChallenge,
  VerifyMfaCode,
} from "services/authService";
import QRCode from "qrcode.react";
import { set } from "date-fns";

function SignIn() {
  const basicStepTab = useRef();
  const mfaStepTab = useRef();
  const newPasswordStepTab = useRef();
  const setupMfaStepTab = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState("");
  const [cognitoSession, setCognitoSession] = useState("");
  const [mfaCode, setMfaCode] = useState(new Array(6).fill(""));
  const [mfaSetupQrUrl, setMfaSetupQrUrl] = useState("");
  const [mfaSecretcode, setMfaSecretcode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [newPasswordHasUpperCase, setNewPasswordHasUpperCase] = useState(false);
  const [newPasswordHasEightChars, setNewPasswordHasEightChars] = useState(
    false
  );
  const [newPasswordHasLowerCase, setNewPasswordHasLowerCase] = useState(false);
  const [newPasswordHasSpecialChar, setNewPasswordHasSpecialChar] = useState(
    false
  );
  const [newPasswordHasNumber, setNewPasswordHasNumber] = useState(false);
  const [
    newPasswordHasNoTrailingSpaces,
    setNewPasswordHasNoTrailingSpaces,
  ] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [mfaSignInError, setMfaSignInError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [mfaSetupError, setMfaSetupError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const emailInputRef = useRef(null);
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const inputRefsSignIn = useRef([...Array(6)].map(() => useRef(null)));
  const inputRefsSetup = useRef([...Array(6)].map(() => useRef(null)));

  // useEffect(() => {
  //   if (mfaStepTab.current && cognitoSession) {
  //     if (inputRefsSignIn.current[0].current) {
  //       inputRefsSignIn.current[0].current.focus();
  //     }
  //   }
  // }, [cognitoSession]);
  // Logica voor qrcode secret code kopieren
  // const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset de tooltip na 2 seconden
  };

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // check of je op mobiel bent of niet voor qr code of code van qr code
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [showQrCode, setShowQrCode] = React.useState(false);

  useEffect(() => {
    if (mfaStepTab.current && cognitoSession) {
      if (inputRefsSignIn.current[0] && inputRefsSignIn.current[0].current) {
        inputRefsSignIn.current[0].current.focus(); // Set focus to the first input
      }
    }
  }, [cognitoSession, mfaStepTab]);

  const handleChangeSignIn = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSignIn.current.length - 1) {
        inputRefsSignIn.current[index + 1].current.focus();
      }
    }
  };

  const handleChangeSetup = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSetup.current.length - 1) {
        inputRefsSetup.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownSignIn = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSignIn.current[index - 1].current.focus();
    }
  };

  const handleKeyDownSetup = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSetup.current[index - 1].current.focus();
    }
  };

  const handlePasteSignIn = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSignIn.current[5].current.focus();
    }
  };

  const handlePasteSetup = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSetup.current[5].current.focus();
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await signIn(email, password);
      if (response.ChallengeName === ChallengeNameType.SOFTWARE_TOKEN_MFA) {
        setCognitoSession(response.Session);
        mfaStepTab.current.click();
      }
      if (response.ChallengeName === ChallengeNameType.NEW_PASSWORD_REQUIRED) {
        setCognitoSession(response.Session);
        newPasswordStepTab.current.click();
      }
      if (response.ChallengeName === ChallengeNameType.MFA_SETUP) {
        const [
          session,
          qrCodeUrl,
          secretCode,
        ] = await RespondToMfaSetupChallenge(response.Session, email);
        setMfaSecretcode(secretCode);
        setCognitoSession(session);
        setMfaSetupQrUrl(qrCodeUrl);
        setupMfaStepTab.current.click();
      }
    } catch (error) {
      setSignInError(error.message);
    }
  };

  const handleSignInMfa = async (e) => {
    e.preventDefault();
    const mfaCodeString = mfaCode.join("");

    try {
      const session = await RespondToMfaChallenge(
        email,
        mfaCodeString,
        cognitoSession,
        rememberMe
      );
      if (session && typeof session.AccessToken !== "undefined") {
        window.location.href = "/";
      }
    } catch (error) {
      setMfaSignInError(error.message);
    }
  };

  const handleNewPassword = async (e) => {
    e.preventDefault();
    try {
      if (newPassword !== confirmNewPassword) {
        throw new Error("Wachtwoorden komen niet overeen");
      } else {
        const response = await RespondToNewPasswordChallenge(
          email,
          newPassword,
          cognitoSession,
          rememberMe
        );
        if (response.ChallengeName === ChallengeNameType.MFA_SETUP) {
          const [
            session,
            qrCodeUrl,
            secretCode,
          ] = await RespondToMfaSetupChallenge(response.Session, email);
          setMfaSecretcode(secretCode);
          setCognitoSession(session);
          setMfaSetupQrUrl(qrCodeUrl);
          setupMfaStepTab.current.click();
        }
      }
    } catch (error) {
      setNewPasswordError(error.message);
    }
  };

  const handleMfaSetup = async (e) => {
    e.preventDefault();
    const mfaCodeString = mfaCode.join("");
    console.log(mfaSecretcode);
    try {
      setMfaSecretcode(mfaSecretcode);
      const session = await VerifyMfaCode(
        mfaCodeString,
        cognitoSession,
        email,
        rememberMe
      );
      if (session && typeof session.AccessToken !== "undefined") {
        window.location.href = "/";
      }
    } catch (error) {
      setMfaSetupError(error.message);
    }
  };

  const verifyNewPassword = (modifiedPassword) => {
    setNewPasswordHasUpperCase(/[A-Z]/.test(modifiedPassword));
    setNewPasswordHasLowerCase(/[a-z]/.test(modifiedPassword));
    setNewPasswordHasSpecialChar(
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(modifiedPassword)
    );
    setNewPasswordHasEightChars(modifiedPassword.length >= 8);
    setNewPasswordHasNumber(/\d/.test(modifiedPassword));
    setNewPasswordHasNoTrailingSpaces(/\s/g.test(modifiedPassword) === false);
  };

  const titleColor = useColorModeValue("blue.300", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welkom bij het LawDisk™ portaal.
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Vul uw gegevens in.
            </Text>
            <Flex>
              <Tabs>
                <TabList
                  display="none"
                  align="center"
                  alignSelf="center"
                  justifySelf="center"
                >
                  <Tab ref={basicStepTab}></Tab>
                  <Tab ref={mfaStepTab}></Tab>
                  <Tab ref={newPasswordStepTab}></Tab>
                  <Tab ref={setupMfaStepTab}></Tab>
                </TabList>
                <TabPanels
                  variant="unstyled"
                  mt="24px"
                  display="flex"
                  flexDirection="column"
                >
                  {/* Eerste TabPanel: Inloggen */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={signInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Email
                        </FormLabel>
                        <Input
                          ref={emailInputRef} // Add ref for auto focus
                          borderRadius="15px"
                          mb="10px"
                          w="100%"
                          fontSize="sm"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Uw emailadres"
                          size="lg"
                        />
                        <FormErrorMessage mb="10px" fontSize="md">
                          {signInError}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Wachtwoord
                        </FormLabel>
                        <InputGroup>
                          <Input
                            borderRadius="15px"
                            mb="10px"
                            fontSize="sm"
                            type={show ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Uw wachtwoord"
                            size="lg"
                          />
                          <InputRightElement>
                            <Button
                              h="100%"
                              size="sm"
                              variant="ghost"
                              onClick={handleClick}
                            >
                              {show ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage mb="10px"></FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignIn}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                        <FormErrorMessage>{signInError}</FormErrorMessage>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Tweede TabPanel: 2FA Codee */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={mfaSignInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          2FA Code
                        </FormLabel>

                        <Flex justify="center" mb="24px">
                          {mfaCode.map((_, index) => (
                            <Input
                              key={index}
                              ref={inputRefsSignIn.current[index]}
                              value={mfaCode[index]}
                              onChange={(e) =>
                                handleChangeSignIn(e.target.value, index)
                              }
                              onKeyDown={(e) => handleKeyDownSignIn(e, index)}
                              onPaste={(e) => handlePasteSignIn(e)}
                              maxLength={1}
                              inputMode="numeric"
                              pattern="\d*"
                              textAlign="center"
                              fontSize="2xl"
                              width="50px"
                              height="50px"
                              borderColor="gray.300"
                              _focus={{ borderColor: "blue.300" }}
                              _hover={{ borderColor: "gray.500" }}
                              mx="5px"
                            />
                          ))}
                        </Flex>

                        <FormControl
                          display="flex"
                          alignItems="center"
                          mb="36px"
                        >
                          <Checkbox
                            isChecked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            colorScheme="blue"
                          >
                            Onthoud mij voor 14 dagen op dit apparaat
                          </Checkbox>
                        </FormControl>

                        <FormErrorMessage>{mfaSignInError}</FormErrorMessage>

                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignInMfa}
                          _hover={{ bg: "blue.200" }}
                          _active={{ bg: "blue.400" }}
                        >
                          LOG IN
                        </Button>
                      </FormControl>
                    </form>
                  </TabPanel>
                  {/* Derde TabPanel: Nieuwe Wachtwddoord */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={newPasswordError}>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Nieuw wachtwoord
                          </FormLabel>
                          <InputGroup>
                            <Input
                              borderRadius="15px"
                              mb="10px"
                              fontSize="sm"
                              type={show ? "text" : "password"}
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                verifyNewPassword(e.target.value);
                              }}
                              placeholder="Uw nieuwe wachtwoord"
                              size="lg"
                            />
                            <InputRightElement>
                              <Button
                                h="100%"
                                size="sm"
                                variant="ghost"
                                onClick={handleClick}
                              >
                                {show ? <FaEyeSlash /> : <FaEye />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage mb="10px">
                            {newPasswordError}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Bevestig wachtwoord
                          </FormLabel>
                          <InputGroup>
                            <Input
                              borderRadius="15px"
                              mb="10px"
                              fontSize="sm"
                              type={show ? "text" : "password"}
                              value={confirmNewPassword}
                              onChange={(e) =>
                                setConfirmNewPassword(e.target.value)
                              }
                              placeholder="Bevestig uw nieuwe wachtwoord"
                              size="lg"
                            />
                            <InputRightElement>
                              <Button
                                h="100%"
                                size="sm"
                                variant="ghost"
                                onClick={handleClick}
                              >
                                {show ? <FaEyeSlash /> : <FaEye />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
                            <List spacing={3}>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasEightChars
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasEightChars
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet minstens 8 tekens bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasLowerCase
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasLowerCase
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een kleine letter bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasUpperCase
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasUpperCase
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een hoofdletter bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasSpecialChar
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasSpecialChar
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een speciaal teken bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasNumber
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasNumber
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een nummer bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasNoTrailingSpaces
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasNoTrailingSpaces
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord mag geen voor- of achterspaties
                                bevatten
                              </ListItem>
                            </List>
                          </FormLabel>
                          <FormErrorMessage mb="10px"></FormErrorMessage>
                        </FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleNewPassword}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                        <FormErrorMessage>{newPasswordError}</FormErrorMessage>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Fourth TabPanel: MFA Setup */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={mfaSetupError}>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            <Flex my="10px">
                              U moet 2FA instellen om de applicatie veilig te
                              gebruiken.
                            </Flex>

                            {isMobile ? (
                              <Flex>
                                <Flex my="20px" justify="center">
                                  Als u de QR-code niet kunt scannen, kunt u op
                                  de onderstaande knop klikken om de code te
                                  kopiëren. Voer deze code handmatig in uw
                                  authenticator-app in.
                                </Flex>
                              </Flex>
                            ) : (
                              <Flex my="20px" justify="center">
                                <Flex>
                                  Installeer een authenticator-app (bijv. Google
                                  Authenticator) op uw mobiele apparaat en scan
                                  de QR-code.
                                </Flex>
                              </Flex>
                            )}
                            {isMobile ? (
                              <Flex my="20px" justify="center">
                                <Box
                                  padding="4"
                                  border="1px solid"
                                  borderColor="gray.300"
                                  borderRadius="md"
                                  textAlign="center"
                                  fontSize="xs"
                                  fontWeight="bold"
                                  cursor="pointer"
                                  _hover={{ bg: "gray.50" }}
                                  maxW="80%"
                                  overflowWrap="break-word"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  onClick={async () => {
                                    if (mfaSecretcode) {
                                      try {
                                        await navigator.clipboard.writeText(
                                          mfaSecretcode
                                        );
                                        handleCopy();
                                      } catch (error) {
                                        console.error(
                                          "Kopiëren naar klembord mislukt:",
                                          error
                                        );
                                      }
                                    } else {
                                      console.error(
                                        "mfaSecretcode is niet beschikbaar."
                                      );
                                    }
                                  }}
                                >
                                  <Tooltip
                                    label={
                                      isCopied
                                        ? "Gekopieerd!"
                                        : "Klik om te kopiëren"
                                    }
                                    placement="top"
                                  >
                                    {mfaSecretcode
                                      ? "Klik hier om uw code te kopiëren"
                                      : "Code niet beschikbaar"}
                                  </Tooltip>
                                </Box>
                              </Flex>
                            ) : (
                              <Flex justify="center">
                                <QRCode value={mfaSetupQrUrl} />
                              </Flex>
                            )}
                            {/* Error Message */}
                            <FormErrorMessage>{mfaSetupError}</FormErrorMessage>
                          </FormLabel>
                          <VSeparator />

                          {/* MFA Code Input */}
                          <Flex justify="center">
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Voer een code in vanuit uw authenticator-app.
                            </FormLabel>
                          </Flex>

                          <Flex justify="center" mb="24px">
                            {mfaCode.map((_, index) => (
                              <Input
                                key={index}
                                ref={inputRefsSetup.current[index]}
                                value={mfaCode[index]}
                                onChange={(e) =>
                                  handleChangeSetup(e.target.value, index)
                                }
                                onKeyDown={(e) => handleKeyDownSetup(e, index)}
                                onPaste={(e) => handlePasteSetup(e)}
                                maxLength={1}
                                inputMode="numeric"
                                pattern="\d*"
                                textAlign="center"
                                fontSize="2xl"
                                width="50px"
                                height="50px"
                                borderColor="gray.300"
                                _focus={{ borderColor: "blue.300" }}
                                _hover={{ borderColor: "gray.500" }}
                                mx="5px"
                              />
                            ))}
                          </Flex>

                          <Button
                            fontSize="sm"
                            type="submit"
                            bg="blue.300"
                            w="100%"
                            h="45"
                            mb="20px"
                            color="white"
                            mt="20px"
                            onClick={handleMfaSetup}
                            _hover={{ bg: "blue.200" }}
                            _active={{ bg: "blue.400" }}
                          >
                            LOG IN
                          </Button>

                          <FormErrorMessage>{mfaSetupError}</FormErrorMessage>
                        </FormControl>
                      </FormControl>
                    </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={cover}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
