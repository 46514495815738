export const columnsDataConversations = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Gesprek",
    accessor: "text",
  },
  {
    Header: "Samenvatting",
    accessor: "summary",
  },
  {
    Header: "Tags",
    accessor: "tags"
  }
];

export const columnsData1 = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "OFFICE",
    accessor: "office",
  },
  {
    Header: "AGE",
    accessor: "age",
  },
  {
    Header: "START DATE",
    accessor: "date",
  },
  {
    Header: "SALARY",
    accessor: "salary",
  },
];

export const columnsData2 = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "REVENUE",
    accessor: "revenue",
  },
];


export const columnsConversationOverView = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "DATUM",
    accessor: "createdAt"
  },
  {
    Header: "BRON",
    accessor: "userId"
  },
  {
    Header: "SAMENVATTING",
    accessor: "summary",
  },
  {
    Header: "TAGS",
    accessor: "tags",
  }
]

export const columnsConversationOverViewMobile = [
  {
    Header: "TAGS",
    accessor: "tags",
  },
  {
    Header: "SAMENVATTING",
    accessor: "summary",
  },
  {
    Header: "DATUM",
    accessor: "createdAt"
  }
]
