import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  HStack,
  Select,
  Text,
  useDisclosure,
  useToast,
  Box,
  ButtonGroup,
} from "@chakra-ui/react";
import { FaMicrophone } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import { SettingsIcon } from "@chakra-ui/icons";
import CircularWaveBox from "components/Recording/CircularWaveBox";
import { getJuvolySessionId } from "api/lawdiskService";
import { addConversation } from "api/lawdiskService";
import { FaLock } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

function RecordingButtonMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRecording, setIsRecording] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [waitingForSetup, setWaitingForSetup] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [conversation, setConversation] = useState("");
  const toast = useToast();

  useEffect(() => {
    // Fetch available audio input devices
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const audioDevices = deviceInfos.filter(
        (device) => device.kind === "audioinput"
      );
      setDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      }
    });
  }, []);

  const handleButtonClick = async () => {
    if (isRecording) {
      // If already recording, stop the recording and close the modal
      await stopRecording();
      onClose();
    } else {
      // If not recording, start the recording
      await startRecording();
    }
  };

  const startRecording = async () => {
    setWaitingForSetup(true);
    try {
      const sessionId = await getJuvolySessionId();
      if (!sessionId) throw new Error("Failed to get session ID.");

      const webSocket = new WebSocket(
        `wss://ai.juvoly.nl/ws/speech/lawdisk/${sessionId}`
      );

      webSocket.onopen = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: { deviceId: selectedDeviceId } })
          .then((stream) => {
            setMediaStream(stream);
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            recorder.ondataavailable = ({ data }) => {
              if (webSocket.readyState === WebSocket.OPEN) {
                webSocket.send(data);
              }
            };

            recorder.start(250);
            setIsRecording(true);
            setWaitingForSetup(false);
          })
          .catch((error) => {
            toast({
              title: "Error",
              description:
                "Geef a.u.b. toegang tot de microfoon. Ververs de pagina om het opnieuw te proberen.",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
            console.error("Microphone access error:", error);
            setIsRecording(false);
            setWaitingForSetup(false);
          });
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result.type === "transcript") {
          const completedText = result.transcript.completed
            .map((c) => c.sentence)
            .join(" ");
          setConversation((prev) => prev + " " + completedText);
        }
      };

      webSocket.onclose = () => {
        stopRecording();
      };
    } catch (error) {
      console.error("Error starting recording:", error);
      setWaitingForSetup(false);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }

    setIsRecording(false);
    setWaitingForSetup(false);
    setIsDisabled(false);

    try {
      await addConversation(conversation);
      toast({
        title: "Conversatie toegevoegd",
        description: "U kunt de transcriptie nu terug zien in uw overzicht.",
        status: "info",
        duration: 6000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving conversation:", error);
      toast({
        title: "Error",
        description: "Failed to save conversation.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  return (
    <>
      <Box>
        <ButtonGroup size="sm" isAttached>
          <Button
            onClick={async () => {
              onOpen();
              await startRecording();
            }}
            leftIcon={<FaMicrophone />}
            colorScheme="red"
            w="100%"
            pl={"27.54px"}
            pr={"27.54px"}
          >
            <Text fontSize="sm">
              {isRecording ? "STOP TRANSCRIBEREN" : "START TRANSCRIBEREN"}
            </Text>
          </Button>
        </ButtonGroup>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
        <ModalOverlay />
        <ModalContent
          height="100vh"
          bg="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ModalHeader mt="50px">
            Tijdens het transcriberen kunt u de app niet verlaten.
          </ModalHeader>
          <ModalBody>
            <FaLock
              size={20}
              color="gray"
              style={{
                position: "absolute",
                right: "16px", // Adjust the distance from the right edge
                top: "120px", // Approximate height for iPhone lock button
              }}
            />
            <FaArrowRight
              className="animate-bounce"
              size={20}
              color="gray"
              style={{
                position: "absolute",
                right: "46px", // Adjust the distance from the right edge
                top: "120px", // Approximate height for iPhone lock button
              }}
            />
        U kunt uw telefoon gewoon vergrendelen; de transcriptie zal gewoon doorgaan.
            {/* <Text fontWeight="bold" mb={2}>
              Selecteer uw microfoon:
            </Text> */}
            {/* <Select
              onChange={handleDeviceChange}
              value={selectedDeviceId}
              isDisabled={isRecording}
            >
              {devices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId}`}
                </option>
              ))}
            </Select> */}
            {isRecording && <CircularWaveBox deviceId={selectedDeviceId} />}
          </ModalBody>
          <ModalFooter>
            <HStack my="50px">
              <Button
                spinner={<PulseLoader size={10} color="white" />}
                leftIcon={<FaMicrophone />}
                onClick={handleButtonClick}
                colorScheme="red"
                isLoading={waitingForSetup || isDisabled}
                isDisabled={isDisabled}
                w="100%"
              >
                <Text fontSize="sm">
                  {isRecording ? "STOP TRANSCRIBEREN" : "START TRANSCRIBEREN"}
                </Text>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RecordingButtonMobile;
