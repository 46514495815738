import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { format } from "date-fns";
import { changelogData as updates } from "./changelogData";

function Changelog() {
  const [changelogData, setChangelogData] = useState([]);

  useEffect(() => {
    setChangelogData(updates);
  }, []);

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const badgeColors = {
    Experimenteel: "purple",
    Success: "green",
    Removed: "red",
    Default: "gray",
  };

  return (
    <Flex flexDirection="column" pt={{ base: "100px", md: "75px" }} px={{ base: "4", md: "8" }}>
      <Box>
        <Heading mb="6" textAlign="center">Changelog</Heading>
        <Stack divider={<StackDivider />} spacing="8">
          {changelogData.map((entry, index) => (
            <Card key={index} bg={bgProfile} p="6" boxShadow="lg">
              <CardBody>
                <Flex direction="column" mb="6" align={{ base: "center", md: "flex-start" }}>
                  <Heading size="md" mb="1" textAlign={{ base: "center", md: "left" }}>
                    Versie {entry.version}
                  </Heading>
                  <Text fontSize="sm" color="gray.500" mb="4" textAlign={{ base: "center", md: "left" }}>
                    {format(new Date(entry.date), "dd MMMM yyyy")}
                  </Text>
                </Flex>
                <Stack spacing="4">
                  {entry.changes.map((change, i) => (
                    <Flex key={i} align="center" justify="space-between" flexWrap="wrap" gap="2">
                      <Text fontSize="md" flex="1" textAlign={{ base: "center", md: "left" }}>
                        {change.text}
                      </Text>
                      {change.type && (
                        <Badge
                          colorScheme={badgeColors[change.type] || "gray"}
                          minW="80px"
                          textAlign="center"
                          mt={{ base: "2", md: "0" }}
                        >
                          {change.type}
                        </Badge>
                      )}
                    </Flex>
                  ))}
                </Stack>
              </CardBody>
            </Card>
          ))}
        </Stack>
      </Box>
    </Flex>
  );
}

export default Changelog;
