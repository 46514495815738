

// Chakra imports
import { Box, ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import 'assets/css/pud-dashboard-styles.css';
import Footer from 'components/Footer/Footer.js';
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import FixedPlugin from 'components/FixedPlugin/FixedPlugin';
import Configurator from 'components/Configurator/Configurator';

import routes from 'routes.js';
import theme from 'theme/theme.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function Pages(props) {
	const [ sidebarVariant, setSidebarVariant ] = useState('transparent');
	const [ fixed, setFixed ] = useState(false);

	const { ...rest } = props;
	// ref for the wrapper div
	const wrapper = React.createRef();
	React.useEffect(() => {
		document.body.style.overflow = 'unset';
		// Specify how to clean up after this effect:
		return function cleanup() {};
	});
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondaryNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/auth') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};
	const navRef = React.useRef();
	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = 'ltr';
	document.documentElement.layout = 'auth';
	return (
		<ChakraProvider theme={theme} resetCss={false} w='100%'>
			<Box ref={navRef} w='100%'>
				<Portal containerRef={navRef}>
					<AuthNavbar logoText='LAWDISK' />
				</Portal>
					<Box w='100%'>
						<Box ref={wrapper} w='100%'>
							<Switch>
								{getRoutes(routes)}
								<Redirect from='/auth' to='/auth/sign-in' />
							</Switch>
						</Box>
					</Box>
						<Box px='24px' mx='auto' width='1044px' maxW='100%'>
							<Footer />
						</Box>
						<Portal>
							<FixedPlugin fixed={fixed} onOpen={onOpen} />
						</Portal>
						<Configurator
							secondary={getActiveNavbar(routes)}
							isOpen={isOpen}
							onClose={onClose}
							isChecked={fixed}
							onSwitch={(value) => {
								setFixed(value);
							}}
							onOpaque={() => setSidebarVariant('opaque')}
							onTransparent={() => setSidebarVariant('transparent')}
						/>
				</Box>
			</ChakraProvider>
	);
}
