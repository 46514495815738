import {
  Avatar, Badge, Box,
  Button, ButtonGroup,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead, Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { getUserId, getUserName } from "../../extensions/userInfoExtension";
import { IoOpenOutline } from "react-icons/io5";
import { FaTrashAlt } from "react-icons/fa";
import { format } from 'date-fns';
import { deleteConversation } from "api/lawdiskService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMediaQuery } from 'react-responsive';

function ConversationsTable(props) {
  const OverlayDelete = () => (
    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
  );

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [conversationIdToDelete, setConversationIdToDelete] = useState(null);
  const [overlay, setOverlay] = React.useState(<OverlayDelete />);
  const { columnsData, tableData, dataLoaded, onClickDelete } = props;
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'createdAt', // sort by accessor! Not HEADER 
            desc: true, 
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance;

  // Functie om de paginanummers te genereren
  const createPages = (pageCount, currentPage) => {
    const pages = [];
    if (currentPage > 2) pages.push(1, '...');
    if (currentPage > 1) pages.push(currentPage - 1);
    pages.push(currentPage);
    if (currentPage < pageCount - 1) pages.push(currentPage + 1);
    if (currentPage < pageCount - 2) pages.push('...', pageCount);
    return pages;
  };

  const { pageIndex, pageSize } = state;

  const history = useHistory();
  
  const handleRowClick = (id) => { 
    let path = `/admin/dashboard/detail/${id}`; 
    history.push(path);
  };

  return (
    <>
      <Flex direction="column" w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex align={{ sm: "flex-start", lg: "flex-end" }} justify={{ sm: "flex-start", lg: "flex-end" }} w="100%" px="22px" mb="36px">
          <Input
            type="text"
            placeholder="Zoeken..."
            minW="75px"
            maxW="175px"
            fontSize="sm"
            _focus={{ borderColor: "teal.300" }}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Flex>
        <Box overflowX="auto" w="100%">
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    (isMobile && (column.Header === "ID" || column.Header === "SAMENVATTING")) ? null : (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="0px"
                        key={index}
                      >
                        <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    )
                  ))}
                </Tr>
              ))}
            </Thead>
            {dataLoaded ?
              <Tbody {...getTableBodyProps()}>
                {
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr
                        {...row.getRowProps()}
                        key={index}
                        onClick={() => handleRowClick(row.original.id)}
                        cursor="pointer"
                        _hover={{ bg: "gray.100" }}
                      >
                        {row.cells.map((cell, index) => {1
                          let data = "";
                          const fontSize = isMobile ? "11px" : "inherit";

                          // Verberg de ID en Samenvatting-kolom op mobiel
                          if (isMobile && (cell.column.Header === "ID" || cell.column.Header === "SAMENVATTING")) {
                            return null;
                          }

                          else if (cell.column.Header === "ID") {
                            data = (
                              <Flex align="center">
                                <Text>{String(cell.value).split("-")[0].toUpperCase()}</Text>
                              </Flex>
                            );
                          } else if (cell.column.Header === "DATUM") {
                            const date = new Date(cell.value);
                            const formattedDate = format(date, 'dd-MM-yy HH:mm');
                            data = (
                              <Flex align="center">
                                <Text style={{ fontSize }}>{formattedDate}</Text>
                              </Flex>
                            );
                          } else if (cell.column.Header === "SAMENVATTING") {
                            const summary = cell.value === null ? "" : String(cell.value);
                            const displayText = summary.length > (isMobile ? 150 : 350) ? `${summary.substring(0, isMobile ? 150 : 350).trim()}...` : summary;
                            data = <Text style={{ fontSize }}>{displayText}</Text>;
                          } else if (cell.column.Header === "BRON") {
                            const userIdWithoutDomain = getUserId().replace(/@.*/, "");
                            const userName = userIdWithoutDomain === cell.value ? getUserName() : cell.value;
                                                        data = (
                              <Flex align="center">
                                <Avatar name={userName} w="30px" h="30px" me="6px" />
                                {!isMobile && <Text>{userName}</Text>}
                              </Flex>
                            );
                          } else if (cell.column.Header === "TAGS") {
                            data = (
                              <Stack>
                                {cell.value?.map((tag, index) => (
                                  <Box key={index}>
                                    <Badge colorScheme="green" textAlign="center">{tag}</Badge>
                                  </Box>
                                ))}
                              </Stack>
                            );
                          }
                          return (
                            <Td {...cell.getCellProps()} key={index} fontSize={{ sm: "14px" }} minW={{ sm: "100px", md: "200px", lg: "auto" }}>
                              {data}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </Tbody> :
              <Spinner size='lg' />
            }
          </Table>
        </Box>

        <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto" pr="20px">
      {createPages(pageCount, pageIndex + 1).map((page, index) => (
        page === '...' ? (
          <Flex key={index} align="center" justify="center" width="40px">
            <Text fontSize="lg" color="gray.400">...</Text>
          </Flex>
        ) : (
          <Button
            variant="outline"
            onClick={() => gotoPage(page - 1)}
            bg={page === pageIndex + 1 ? "teal.300" : "#fff"}
            border="1px solid lightgray"
            borderRadius="50%"
            w="40px"
            h="40px"
            _hover={{ bg: "gray.200", opacity: "0.7", borderColor: "gray.500" }}
            key={index}
          >
            <Text fontSize="sm" color={page === pageIndex + 1 ? "#fff" : "gray.600"}>{page}</Text>
          </Button>
        )
      ))}

</Stack>

      </Flex>
    </>
  );
}

export default ConversationsTable;