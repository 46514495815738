

// chakra imports
import { Avatar, Flex, Text, useColorModeValue, Center, Spacer } from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import React from "react";

export function ItemContent(props) {
  const notificationColor = useColorModeValue("gray.700", "white");
  const spacing = " ";
  return (
    <>
      <Avatar
        name={props.boldInfo}
        borderRadius="12px"
        me="16px"
      />
      <Flex flexDirection="column">
        <Text fontSize="14px" mb="5px" color={notificationColor}>
          {props.info}
          {spacing}
          <Text fontWeight="bold" fontSize="14px" as="span">
            {props.boldInfo}
          </Text>
        </Text>
      </Flex>
    </>
  );
}

export function LogoutItemContent(props) {
  const notificationColor = useColorModeValue("gray.700", "white");
  const spacing = " ";
  return (
    <>
      <Flex flexDirection="column">        
        <Text fontSize="14px" mb="5px" color={notificationColor}>
          {props.info}
          {spacing}
          <Text fontWeight="bold" fontSize="14px" as="span">
            {props.boldInfo}
          </Text>
        </Text>
      </Flex>
    </>
  );
}