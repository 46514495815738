

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { LawDiskLogo, RocketIcon } from "components/Icons/Icons";
import PropTypes from "prop-types";
import React from "react";

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const textColor = useColorModeValue("gray.700", "#fff");
  const { logo, logoText, secondary, ...rest } = props;


  let navbarBg = "none";
  let navbarBorder = "none";
  let navbarShadow = "initial";
  let navbarFilter = "initial";
  let navbarBackdrop = "none";
  let bgButton = "black";
  let colorButton = "gray.700";
  let mainText = "#fff";
  let navbarPosition = "absolute";

  var brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/`}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <LawDiskLogo w="32px" h="32px" me="10px" />
      <Text fontSize="sm" mt="3px">
        {logoText}
      </Text>
    </Link>
  );
  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="15px"
      px="16px"
      py="22px"
      mx="auto"
      width="1044px"
      maxW="90%"
      alignItems="center"
    >
      <Flex w="100%" justifyContent={{ sm: "start", lg: "space-between" }}>
        {brand}
        <Box ms={{ base: "auto", lg: "0px" }} display={{ lg: "none" }}>
        </Box>
        {/* {linksAuth} */}
        <Link href="https://lawdisk.nl">
          <Button
            bg={bgButton}
            color={colorButton}
            fontSize="xs"
            variant="no-hover"
            borderRadius="35px"
            px="30px"
            color="#fff"
            display={{
              sm: "flex",
              lg: "flex",
            }}
          >
            Koop nu uw LawDisk&#8482;
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
