import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { jwtDecode } from "jwt-decode";
import { refreshAccessToken } from "services/authService";
import { hotjar } from "react-hotjar";
import { injectContentsquareScript } from "@contentsquare/tag-sdk";

injectContentsquareScript({
  siteId: "5196251",
  async: true,
  defer: false,
  onLoad: () => {
    // Initialize Hotjar using Contentsquare SDK
    window.cs("Hotjar", "initialize", {
      hotjarId: 5196251, // Replace with your Hotjar Site ID
      hotjarSnippetVersion: 6, // Typically version 6
    });
  },
});

const isAuthenticated = () => {
  const accessToken = localStorage.getItem("accessToken");
  const decodedToken = accessToken ? jwtDecode(accessToken) : null;
  const currentDate = new Date();

  if (decodedToken && decodedToken.exp * 1000 > currentDate.getTime()) {
    return true;
  }

  return false;
};

const App = () => {
  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken =
        localStorage.getItem("refreshToken") ||
        sessionStorage.getItem("refreshToken");

      if (accessToken && refreshToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        // Als het token bijna verlopen is (binnen 5 minuten), vernieuw het dan
        if (decodedToken.exp < currentTime + 5 * 60) {
          try {
            await refreshAccessToken();
          } catch (error) {
            console.error("Error refreshing token:", error);
          }
        }
      }
    };

    checkAndRefreshToken();
    const interval = setInterval(checkAndRefreshToken, 3 * 60 * 1000); // Check elke 5 minuten

    return () => clearInterval(interval);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={`/auth`}
          render={(props) => {
            return isAuthenticated() ? (
              <Redirect to="/" />
            ) : (
              <Route component={AuthLayout} />
            );
          }}
        />
        <Route
          path={`/admin`}
          render={(props) => {
            return isAuthenticated() ? (
              <Route component={AdminLayout} />
            ) : (
              <Redirect to="/auth/sign-in" />
            );
          }}
        ></Route>
        <Redirect
          from={`/`}
          to={isAuthenticated() ? "/admin/dashboard/notulen" : "/auth/sign-in"}
        />
      </Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
