import { Box, Flex, Stack } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  columnsConversationOverViewMobile,
  columnsConversationOverView,
} from "variables/columnsData";
import { getConversations } from "api/lawdiskService";
import ConversationsTable from "components/Tables/ConversationsTable";
import RecordingButton from "components/Recording/RecordingButton";
import FileUploadCard from "components/Card/FileUploadCard";
import RecordingButtonMobile from "components/Recording/RecordingButtonMobile";

function OrderList() {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [conversations, setConversations] = useState([]);

  const fetchConversations = async () => {
    try {
      const conversations = await getConversations();
      setConversations(conversations);
      setIsLoaded(true);
    } catch (error) {
      console.error("Error bij het ophaalen van conversaties:", error);
      setConversations([]);
      setIsLoaded(true); // Even on error, we mark it as loaded to avoid infinite loading state
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "0px", md: "20px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
      >
        <Box display={{ base: "none", lg: "block" }}>
          <RecordingButton
            onStopRecording={async () => {
              fetchConversations(); // Initial transcription fetch

              // Wait for 5 seconds
              await new Promise((resolve) => setTimeout(resolve, 5000));

              fetchConversations(); // summary and tags fetch
            }}
          />
        </Box>
        <Box display={{ base: "block", lg: "none" }}>
          <RecordingButtonMobile
            onStopRecording={async () => {
              fetchConversations(); // Initial transcription fetch

              // Wait for 5 seconds
              await new Promise((resolve) => setTimeout(resolve, 5000));

              fetchConversations(); // summary and tags fetch
            }}
          />
        </Box>
        <FileUploadCard />
      </Flex>
      <Card px="0px">
        <CardBody>
          <ConversationsTable
            tableData={conversations}
            columnsData={
              isMobile
                ? columnsConversationOverViewMobile
                : columnsConversationOverView
            }
            dataLoaded={isLoaded}
            onClickDelete={fetchConversations}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default OrderList;
