

import { createIcon } from "@chakra-ui/icons";

export const AdobexdLogo = createIcon({
  displayName: "AdobexdLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clip-path="url(#clip0)">
      <path
        d="M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z"
        fill="#470137"
      />
      <path
        d="M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z"
        fill="#FF61F6"
      />
    </g>
  ),
});

export const AtlassianLogo = createIcon({
  displayName: "AtlassianLogo",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        d="M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z"
        fill="#2684FF"
      />
      <path
        d="M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z"
        fill="#2684FF"
      />
    </g>
  ),
});

export const CartIcon = createIcon({
  displayName: "CartIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z"
    />
  ),
});

export const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
});
export const LawDiskLogo = createIcon({
  displayName: "LawDiskLogo",
  viewBox: "0 0 301 300",
  path: (
    <g version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="100%" viewBox="0 0 301 300" enableBackground="new 0 0 301 300">
      <path fill="#D50F8D" opacity="1.000000" stroke="none"
        d="
M0.999999,249.000000 
	C1.000000,243.977905 1.000000,238.955811 1.344666,233.500641 
	C2.627073,233.712845 3.691479,234.231186 4.484065,235.021973 
	C25.267439,255.758530 46.032990,276.513031 66.750839,297.315002 
	C67.738342,298.306519 68.260910,299.761078 69.000000,301.000000 
	C63.977909,301.000000 58.955814,301.000000 53.302864,300.638794 
	C34.681210,297.362244 20.772329,287.807739 11.184735,272.728485 
	C6.626186,265.558868 4.324210,256.954437 0.999999,249.000000 
z"/>
      <path fill="#BA2699" opacity="1.000000" stroke="none"
        d="
M140.000000,301.000000 
	C136.638718,301.000000 133.277420,301.000000 129.454102,300.634766 
	C128.077225,298.762268 127.420219,296.997772 126.210228,295.785034 
	C86.202568,255.687180 46.153500,215.630569 6.036683,175.641968 
	C4.754218,174.363617 2.696384,173.863113 1.000002,173.000000 
	C1.000000,169.307098 1.000000,165.614182 1.344629,161.494568 
	C3.102503,162.188385 4.638738,163.185196 5.909763,164.448792 
	C17.256468,175.729233 28.583912,187.029419 39.857136,198.383255 
	C44.659344,203.219833 49.319382,208.197571 54.033607,213.457092 
	C54.336685,214.357178 54.545719,215.015442 54.978981,215.450272 
	C65.294800,225.803421 75.620232,236.147156 86.000839,246.435181 
	C86.739944,247.167694 87.916100,247.459213 89.172195,248.138245 
	C90.867210,249.944138 92.189484,251.656860 93.704826,253.177139 
	C108.023552,267.542694 122.395180,281.855621 136.684570,296.250214 
	C138.024689,297.600250 138.905792,299.405884 140.000000,301.000000 
z"/>
      <path fill="#1EADE2" opacity="1.000000" stroke="none"
        d="
M237.000000,1.000002 
	C240.692902,1.000000 244.385818,1.000000 248.712128,1.354721 
	C261.097168,3.594927 271.341064,8.608151 280.116333,16.619879 
	C294.326050,29.593161 301.744598,45.530758 300.762329,64.940887 
	C299.457092,64.299301 298.362152,63.816711 297.571869,63.028614 
	C278.105499,43.615734 258.660339,24.181509 239.263535,4.699189 
	C238.271408,3.702692 237.743851,2.243672 237.000000,1.000002 
z"/>
      <path fill="#2F9FDA" opacity="1.000000" stroke="none"
        d="
M302.000000,99.000000 
	C302.000000,102.361671 302.000000,105.723335 301.655334,109.508743 
	C299.904175,108.818779 298.373352,107.829948 297.110291,106.572227 
	C282.894775,92.417046 268.752808,78.187759 254.484360,64.086334 
	C253.072998,62.691513 250.956024,62.010647 249.099579,60.550892 
	C249.032608,57.844872 249.032608,55.586361 249.032608,52.832329 
	C245.253601,52.832329 242.127930,52.832329 238.959137,52.498898 
	C237.772919,50.772278 236.736313,49.272976 235.470306,48.002197 
	C221.643356,34.123146 207.744797,20.315100 193.982498,6.372480 
	C192.596542,4.968367 191.975830,2.808920 191.000000,1.000001 
	C195.025497,1.000000 199.051010,1.000000 203.572021,1.344666 
	C204.834305,2.748724 205.473740,3.935256 206.386246,4.849104 
	C236.879776,35.387184 267.391479,65.907158 297.946716,96.383446 
	C299.060120,97.493942 300.638397,98.138367 302.000000,99.000000 
z"/>
      <path fill="#C31E95" opacity="1.000000" stroke="none"
        d="
M113.000000,301.000000 
	C109.307091,301.000000 105.614182,301.000000 101.426849,300.655334 
	C100.166832,299.252441 99.528595,298.066986 98.617195,297.154297 
	C67.454338,265.947083 36.273331,234.757980 5.049026,203.612274 
	C3.937111,202.503159 2.360190,201.860214 0.999998,201.000000 
	C1.000000,197.307098 1.000000,193.614182 1.344667,189.494415 
	C2.747561,189.833115 3.933091,190.471252 4.845637,191.382690 
	C40.052452,226.546082 75.241180,261.727570 110.387924,296.951019 
	C111.497162,298.062653 112.139824,299.639832 113.000000,301.000000 
z"/>
      <path fill="#22AAE0" opacity="1.000000" stroke="none"
        d="
M236.533142,1.000002 
	C237.743851,2.243672 238.271408,3.702692 239.263535,4.699189 
	C258.660339,24.181509 278.105499,43.615734 297.571869,63.028614 
	C298.362152,63.816711 299.457092,64.299301 300.984283,64.938705 
	C301.558472,64.953186 302.000000,65.000000 302.000000,65.000000 
	C302.000000,70.354927 302.000000,75.709862 301.634766,81.528152 
	C299.762329,81.076454 297.999817,80.417725 296.785553,79.208458 
	C272.349396,54.873291 247.957382,30.493637 223.643906,6.036019 
	C222.366531,4.751080 221.863525,2.696352 221.000000,0.999998 
	C226.022095,1.000000 231.044189,1.000000 236.533142,1.000002 
z"/>
      <path fill="#C02197" opacity="1.000000" stroke="none"
        d="
M113.454109,301.000000 
	C112.139824,299.639832 111.497162,298.062653 110.387924,296.951019 
	C75.241180,261.727570 40.052452,226.546082 4.845637,191.382690 
	C3.933091,190.471252 2.747561,189.833115 1.344667,189.033768 
	C1.000000,185.969406 1.000000,182.938812 1.344666,179.487885 
	C2.748433,179.834030 3.934927,180.472961 4.848300,181.385315 
	C43.386127,219.880356 81.905853,258.393524 120.384560,296.947662 
	C121.495003,298.060272 122.138680,299.638733 123.000000,301.000000 
	C119.969406,301.000000 116.938812,301.000000 113.454109,301.000000 
z"/>
      <path fill="#5F75C4" opacity="1.000000" stroke="none"
        d="
M302.000000,221.000000 
	C302.000000,225.689255 302.000000,230.378510 301.655334,235.500015 
	C300.020020,234.934311 298.580170,234.079163 297.464569,232.913666 
	C292.659027,227.893204 288.078094,222.652969 283.180603,217.726151 
	C264.339355,198.772003 245.402481,179.912933 226.521759,160.997940 
	C225.243698,159.717560 224.134857,158.268265 222.746429,156.561234 
	C220.697021,156.094467 218.848495,155.963989 216.957458,155.498718 
	C215.277023,152.498077 213.639099,149.832260 212.460297,147.099869 
	C217.612885,146.744019 222.306381,146.454742 227.042267,146.499069 
	C228.228165,148.226898 229.263962,149.728561 230.531357,150.999191 
	C249.069168,169.584045 267.646881,188.129150 286.182526,206.716110 
	C287.692291,208.230057 288.782471,210.191620 290.385406,211.577637 
	C294.154602,214.836777 298.116241,217.873367 302.000000,221.000000 
z"/>
      <path fill="#C81A93" opacity="1.000000" stroke="none"
        d="
M0.999998,201.460632 
	C2.360190,201.860214 3.937111,202.503159 5.049026,203.612274 
	C36.273331,234.757980 67.454338,265.947083 98.617195,297.154297 
	C99.528595,298.066986 100.166832,299.252441 100.966217,300.655334 
	C97.307091,301.000000 93.614182,301.000000 89.426926,300.655334 
	C88.166969,299.252350 87.528549,298.067047 86.617241,297.154205 
	C59.454998,269.946411 32.274578,242.756760 5.049071,215.612335 
	C3.936827,214.503403 2.360245,213.860199 0.999998,213.000000 
	C1.000000,209.307098 1.000000,205.614182 0.999998,201.460632 
z"/>
      <path fill="#5F75C4" opacity="1.000000" stroke="none"
        d="
M70.000000,1.000001 
	C73.362099,1.000000 76.724205,1.000000 80.576897,1.344674 
	C81.948456,2.863047 82.702629,4.163271 83.729012,5.191854 
	C106.485039,27.996521 129.267700,50.774620 152.063919,73.539116 
	C152.990356,74.464272 154.098633,75.207344 155.072998,76.488831 
	C154.960312,80.294624 154.896805,83.646835 154.480438,86.994110 
	C151.473907,85.326210 148.820267,83.663246 146.100403,81.664352 
	C145.700897,80.593277 145.367599,79.858147 144.898132,78.811287 
	C143.511124,77.169479 142.294403,75.805283 141.004333,74.514397 
	C118.436577,51.932606 95.835777,29.383684 73.338936,6.731517 
	C71.831039,5.213215 71.095016,2.928339 70.000000,1.000001 
z"/>
      <path fill="#557EC8" opacity="1.000000" stroke="none"
        d="
M302.000000,196.000000 
	C302.000000,200.689255 302.000000,205.378510 301.634766,210.529785 
	C299.767883,210.082550 298.001923,209.432129 296.812469,208.217224 
	C292.651459,203.967209 288.816406,199.396423 284.628754,195.174393 
	C270.144623,180.571320 255.550476,166.077423 241.033340,151.506973 
	C238.588242,149.052902 236.317017,146.425583 234.132874,143.586914 
	C236.827179,141.517929 239.354050,139.740341 242.191467,138.101486 
	C252.153320,147.812119 261.877350,157.312164 271.431488,166.980087 
	C278.290924,173.921204 284.779510,181.232437 291.723206,188.085007 
	C294.777710,191.099396 298.552338,193.384033 302.000000,196.000000 
z"/>
      <path fill="#CB1791" opacity="1.000000" stroke="none"
        d="
M0.999998,213.458069 
	C2.360245,213.860199 3.936827,214.503403 5.049071,215.612335 
	C32.274578,242.756760 59.454998,269.946411 86.617241,297.154205 
	C87.528549,298.067047 88.166969,299.252350 88.966293,300.655334 
	C85.638710,301.000000 82.277428,301.000000 78.424492,300.655304 
	C77.167915,299.252960 76.530022,298.068573 75.619499,297.156219 
	C52.123074,273.612762 28.608435,250.087402 5.046554,226.609497 
	C3.934803,225.501724 2.359444,224.859207 0.999999,224.000000 
	C1.000000,220.638718 1.000000,217.277420 0.999998,213.458069 
z"/>
      <path fill="#5A7AC6" opacity="1.000000" stroke="none"
        d="
M155.122192,76.035248 
	C154.098633,75.207344 152.990356,74.464272 152.063919,73.539116 
	C129.267700,50.774620 106.485039,27.996521 83.729012,5.191854 
	C82.702629,4.163271 81.948456,2.863047 81.033745,1.344674 
	C85.024544,1.000000 89.049088,1.000000 93.570572,1.344671 
	C95.298508,3.213088 96.403908,4.862053 97.779442,6.241684 
	C116.626511,25.144903 135.520767,44.001064 154.367126,62.904991 
	C155.869720,64.412178 157.104446,66.186424 158.291565,68.123016 
	C157.120377,70.951401 156.121292,73.493324 155.122192,76.035248 
z"/>
      <path fill="#BC2498" opacity="1.000000" stroke="none"
        d="
M123.428467,301.000000 
	C122.138680,299.638733 121.495003,298.060272 120.384560,296.947662 
	C81.905853,258.393524 43.386127,219.880356 4.848300,181.385315 
	C3.934927,180.472961 2.748433,179.834030 1.344666,179.033783 
	C1.000000,177.285645 1.000000,175.571289 1.000002,173.428467 
	C2.696384,173.863113 4.754218,174.363617 6.036683,175.641968 
	C46.153500,215.630569 86.202568,255.687180 126.210228,295.785034 
	C127.420219,296.997772 128.077225,298.762268 128.996033,300.634766 
	C127.285645,301.000000 125.571281,301.000000 123.428467,301.000000 
z"/>
      <path fill="#9348AB" opacity="1.000000" stroke="none"
        d="
M241.000000,301.000000 
	C237.637894,301.000000 234.275803,301.000000 230.452911,300.634735 
	C228.846359,298.531189 227.956497,296.538025 226.516815,295.092499 
	C200.848206,269.319366 175.102798,243.622757 149.420349,217.863388 
	C148.069061,216.508057 147.130173,214.741531 146.453232,213.098633 
	C150.270935,213.076492 153.634689,213.122162 157.004089,213.522705 
	C158.188644,215.600616 159.132660,217.557693 160.581436,219.011917 
	C186.140472,244.667297 211.773407,270.249084 237.336304,295.900635 
	C238.794708,297.364105 239.789459,299.289551 241.000000,301.000000 
z"/>
      <path fill="#FFFFFF" opacity="0.000000" stroke="none"
        d="
M302.000000,237.000000 
	C302.000000,258.253265 302.000000,279.506500 302.000000,301.000000 
	C284.646515,301.000000 267.291901,301.000000 249.472290,300.778473 
	C249.007278,300.556946 249.042831,300.115234 249.435089,300.069336 
	C251.550430,299.626923 253.273529,299.230438 255.373856,298.889069 
	C258.974884,297.567322 262.198700,296.190491 265.790833,294.820068 
	C268.140625,293.568146 270.122101,292.309784 272.451508,291.019409 
	C274.484100,289.785736 276.168701,288.584015 278.089142,287.230499 
	C279.233154,286.087006 280.141327,285.095276 281.344116,283.970581 
	C282.795929,282.573517 283.953125,281.309418 285.391083,279.924133 
	C286.392609,278.867645 287.113312,277.932373 287.991028,276.689789 
	C289.804993,273.933624 291.461945,271.484772 293.406128,268.874908 
	C294.406494,267.141846 295.119568,265.569824 296.004333,263.666138 
	C296.824097,261.234589 297.472198,259.134735 298.426514,256.838013 
	C299.099579,254.760559 299.466370,252.879929 299.911438,250.631058 
	C300.270721,248.841568 300.551697,247.420334 300.899292,245.585815 
	C300.921661,243.780899 300.877441,242.389267 300.899139,240.628357 
	C301.310059,239.172714 301.655029,238.086349 302.000000,237.000000 
z"/>
      <path fill="#FFFFFF" opacity="0.000000" stroke="none"
        d="
M302.000000,64.531342 
	C302.000000,65.000000 301.558472,64.953186 301.336517,64.955368 
	C301.744598,45.530758 294.326050,29.593161 280.116333,16.619879 
	C271.341064,8.608151 261.097168,3.594927 249.172760,1.354721 
	C266.600342,1.000000 284.200714,1.000000 302.000000,1.000000 
	C302.000000,22.020475 302.000000,43.041580 302.000000,64.531342 
z"/>
      <path fill="#FFFFFF" opacity="0.000000" stroke="none"
        d="
M1.000000,53.000000 
	C1.000000,35.739902 1.000000,18.479805 1.000000,1.000000 
	C18.020264,1.000000 35.041473,1.000000 52.474766,1.270244 
	C52.591347,1.748993 52.295845,1.957497 51.609596,2.099941 
	C49.111492,2.671986 47.004131,3.310092 44.520393,3.970505 
	C41.746277,4.959613 39.348541,5.926415 36.600449,6.946605 
	C34.466316,7.981888 32.682537,8.963783 30.600685,10.079065 
	C28.852175,11.439449 27.401733,12.666449 25.623524,13.944235 
	C24.180365,14.961787 23.064970,15.928552 21.656643,17.030933 
	C20.207794,18.426989 19.051878,19.687428 17.620598,21.065559 
	C16.431448,22.173069 15.517664,23.162886 14.366877,24.419674 
	C13.069684,26.422945 12.009495,28.159243 10.636476,30.049343 
	C8.937957,32.803322 7.552268,35.403492 5.998281,38.332058 
	C4.942075,41.107529 4.054167,43.554607 3.084609,46.391273 
	C2.630006,48.839783 2.257052,50.898701 1.662171,52.949387 
	C1.440243,52.941151 1.000000,53.000000 1.000000,53.000000 
z"/>
      <path fill="#FFFFFF" opacity="0.000000" stroke="none"
        d="
M0.999999,249.468658 
	C4.324210,256.954437 6.626186,265.558868 11.184735,272.728485 
	C20.772329,287.807739 34.681210,297.362244 52.835999,300.638794 
	C35.739906,301.000000 18.479811,301.000000 1.000000,301.000000 
	C1.000000,283.979736 1.000000,266.958527 0.999999,249.468658 
z"/>
      <path fill="#4090D2" opacity="1.000000" stroke="none"
        d="
M302.000000,143.000000 
	C302.000000,148.022095 302.000000,153.044189 301.647125,158.498566 
	C297.704041,155.628220 294.004120,152.436188 290.543274,149.003159 
	C278.047485,136.607834 265.649017,124.114372 253.157547,111.714638 
	C252.034729,110.600052 250.506821,109.893539 249.100250,108.535721 
	C249.077896,103.382004 249.122360,98.690071 249.500275,94.041145 
	C251.225586,95.225716 252.723709,96.260597 253.992966,97.525185 
	C268.091187,111.571640 282.116425,125.691689 296.280762,139.670898 
	C297.801300,141.171585 300.075439,141.908676 302.000000,143.000000 
z"/>
      <path fill="#904BAD" opacity="1.000000" stroke="none"
        d="
M1.000000,65.000000 
	C1.000000,62.629444 1.000000,60.258884 1.365259,57.448135 
	C3.468776,58.153732 5.461884,59.043636 6.907437,60.483326 
	C32.680359,86.151764 58.376759,111.897041 84.136009,137.579254 
	C85.491066,138.930267 87.258034,139.868149 88.900375,141.438782 
	C88.923073,144.253113 88.878616,146.626999 88.479179,148.995346 
	C86.400948,147.811188 84.443413,146.867722 82.989250,145.419022 
	C57.333519,119.859673 31.751434,94.226410 6.099534,68.663208 
	C4.636138,67.204865 2.710503,66.210373 1.000000,65.000000 
z"/>
      <path fill="#468BCF" opacity="1.000000" stroke="none"
        d="
M132.000000,1.000001 
	C137.022095,1.000000 142.044189,1.000000 147.568298,1.349200 
	C149.830887,4.083025 151.332764,6.723075 153.394135,8.810667 
	C166.391708,21.973549 179.533081,34.994347 192.564941,48.123615 
	C193.918457,49.487251 194.865387,51.254448 195.538330,52.902245 
	C190.050827,52.924419 185.025970,52.878750 179.995651,52.478134 
	C178.930481,50.519077 178.102737,48.684917 176.775284,47.346313 
	C162.987854,33.443180 149.099380,19.640287 135.307343,5.741693 
	C133.970108,4.394122 133.091507,2.591429 132.000000,1.000001 
z"/>
      <path fill="#9D3FA7" opacity="1.000000" stroke="none"
        d="
M1.000000,100.000000 
	C1.000000,96.969406 1.000000,93.938812 1.344652,90.487946 
	C3.215089,91.300705 4.867064,92.407310 6.247975,93.785507 
	C32.220963,119.707642 58.151859,145.671951 84.137756,171.581116 
	C85.492630,172.931976 87.258705,173.870422 88.899841,175.376083 
	C87.657021,177.874222 86.347710,179.997147 84.914520,181.804688 
	C58.315689,154.948547 31.854918,128.393570 5.312913,101.920036 
	C4.272240,100.882027 2.455261,100.622345 1.000000,100.000000 
z"/>
      <path fill="#A935A1" opacity="1.000000" stroke="none"
        d="
M180.000000,301.000000 
	C176.969406,301.000000 173.938812,301.000000 170.420395,300.655334 
	C168.701492,298.786835 167.596100,297.137665 166.220398,295.758087 
	C146.668198,276.150299 127.073914,256.584503 107.511925,236.986450 
	C106.236877,235.709015 105.141899,234.251831 104.090897,232.585144 
	C105.773018,230.848267 107.327393,229.404465 109.192139,228.099915 
	C131.741531,250.400421 154.007721,272.534668 176.165237,294.777130 
	C177.839050,296.457367 178.739868,298.907654 180.000000,301.000000 
z"/>
      <path fill="#2BA2DB" opacity="1.000000" stroke="none"
        d="
M302.000000,98.555832 
	C300.638397,98.138367 299.060120,97.493942 297.946716,96.383446 
	C267.391479,65.907158 236.879776,35.387184 206.386246,4.849104 
	C205.473740,3.935256 204.834305,2.748724 204.033783,1.344666 
	C206.370560,1.000000 208.741104,1.000000 211.589615,1.344662 
	C212.832001,2.746370 213.469193,3.930451 214.379303,4.842041 
	C242.211060,32.719074 270.061005,60.577999 297.955627,88.392059 
	C299.066284,89.499519 300.641327,90.141266 302.000000,91.000000 
	C302.000000,93.370560 302.000000,95.741112 302.000000,98.555832 
z"/>
      <path fill="#557EC8" opacity="1.000000" stroke="none"
        d="
M158.463654,67.836563 
	C157.104446,66.186424 155.869720,64.412178 154.367126,62.904991 
	C135.520767,44.001064 116.626511,25.144903 97.779442,6.241684 
	C96.403908,4.862053 95.298508,3.213088 94.033760,1.344671 
	C97.692909,1.000000 101.385818,1.000000 105.573135,1.344669 
	C107.187859,3.102411 108.182877,4.640337 109.447525,5.909599 
	C125.326263,21.846224 141.232742,37.755283 157.164597,53.638805 
	C159.393600,55.861057 161.796219,57.909172 163.970612,60.343487 
	C162.036392,63.044910 160.250015,65.440735 158.463654,67.836563 
z"/>
      <path fill="#A538A3" opacity="1.000000" stroke="none"
        d="
M1.000000,124.000000 
	C1.000000,120.637901 1.000000,117.275795 1.344670,113.490616 
	C2.866331,113.951813 4.169888,114.709251 5.201735,115.738976 
	C28.666582,139.155457 52.105049,162.598404 75.530098,186.054718 
	C76.458466,186.984283 77.206215,188.094223 77.907356,189.414612 
	C76.197464,191.179474 74.618347,192.649872 72.911850,193.805908 
	C50.312950,170.951401 27.858116,148.394501 5.316777,125.924370 
	C4.274313,124.885201 2.456633,124.623695 1.000000,124.000000 
z"/>
      <path fill="#3B94D4" opacity="1.000000" stroke="none"
        d="
M302.000000,142.536819 
	C300.075439,141.908676 297.801300,141.171585 296.280762,139.670898 
	C282.116425,125.691689 268.091187,111.571640 253.992966,97.525185 
	C252.723709,96.260597 251.225586,95.225716 249.433380,93.582199 
	C249.077682,89.052780 249.122375,85.025322 249.521988,81.003654 
	C251.479431,82.067757 253.312195,82.893982 254.649078,84.220108 
	C268.888031,98.344536 283.028381,112.568405 297.262878,126.697380 
	C298.608704,128.033218 300.410095,128.910065 302.000000,130.000000 
	C302.000000,134.024551 302.000000,138.049088 302.000000,142.536819 
z"/>
      <path fill="#AD319F" opacity="1.000000" stroke="none"
        d="
M0.999999,141.000000 
	C1.000000,137.637894 1.000000,134.275803 1.368061,130.458847 
	C3.352365,131.031006 5.221047,131.804230 6.547017,133.123077 
	C26.806904,153.273941 47.000240,173.491760 67.189079,193.713943 
	C68.230545,194.757111 69.092278,195.979706 69.909073,197.411621 
	C68.226776,199.148956 66.673096,200.593430 64.808853,201.899094 
	C45.263832,182.605179 26.057158,163.421921 6.741654,144.348892 
	C5.214524,142.840927 2.931650,142.098312 0.999999,141.000000 
z"/>
      <path fill="#3699D6" opacity="1.000000" stroke="none"
        d="
M171.000000,1.000003 
	C175.025177,1.000000 179.050369,1.000000 183.571548,1.344667 
	C185.182693,3.097221 186.173065,4.629332 187.432129,5.893935 
	C201.250534,19.773264 215.144424,33.577705 228.908463,47.510521 
	C230.304626,48.923813 230.987137,51.042133 231.541870,52.901291 
	C227.056168,52.923599 223.029419,52.877892 218.959503,52.498756 
	C217.773148,50.772175 216.736481,49.272926 215.470474,48.002148 
	C201.643555,34.123108 187.745026,20.315075 173.982727,6.372479 
	C172.596741,4.968333 171.975922,2.808916 171.000000,1.000003 
z"/>
      <path fill="#5A7AC6" opacity="1.000000" stroke="none"
        d="
M233.965454,143.878311 
	C236.317017,146.425583 238.588242,149.052902 241.033340,151.506973 
	C255.550476,166.077423 270.144623,180.571320 284.628754,195.174393 
	C288.816406,199.396423 292.651459,203.967209 296.812469,208.217224 
	C298.001923,209.432129 299.767883,210.082550 301.634766,210.995911 
	C302.000000,214.030594 302.000000,217.061188 302.000000,220.545898 
	C298.116241,217.873367 294.154602,214.836777 290.385406,211.577637 
	C288.782471,210.191620 287.692291,208.230057 286.182526,206.716110 
	C267.646881,188.129150 249.069168,169.584045 230.531357,150.999191 
	C229.263962,149.728561 228.228165,148.226898 227.414749,146.431915 
	C229.818375,145.313553 231.891922,144.595932 233.965454,143.878311 
z"/>
      <path fill="#28A5DD" opacity="1.000000" stroke="none"
        d="
M302.000000,90.550018 
	C300.641327,90.141266 299.066284,89.499519 297.955627,88.392059 
	C270.061005,60.577999 242.211060,32.719074 214.379303,4.842041 
	C213.469193,3.930451 212.832001,2.746370 212.033783,1.344662 
	C214.700012,1.000000 217.400024,1.000000 220.550018,0.999998 
	C221.863525,2.696352 222.366531,4.751080 223.643906,6.036019 
	C247.957382,30.493637 272.349396,54.873291 296.785553,79.208458 
	C297.999817,80.417725 299.762329,81.076454 301.634766,81.995758 
	C302.000000,84.700012 302.000000,87.400032 302.000000,90.550018 
z"/>
      <path fill="#9646AA" opacity="1.000000" stroke="none"
        d="
M0.999999,78.000000 
	C1.000000,75.957680 1.000000,73.915367 1.352411,71.470795 
	C3.842240,72.584747 6.245227,73.834129 8.077036,75.657372 
	C33.462364,100.924026 58.759075,126.279686 84.123810,151.567093 
	C85.483383,152.922531 87.253998,153.865677 88.900375,155.440994 
	C88.922836,158.254471 88.878197,160.627670 88.500061,162.958099 
	C86.655540,161.654678 85.036583,160.502151 83.649483,159.117401 
	C57.793205,133.304749 31.976656,107.452263 6.098036,81.662056 
	C4.635118,80.204140 2.709997,79.209999 0.999999,78.000000 
z"/>
      <path fill="#4090D2" opacity="1.000000" stroke="none"
        d="
M196.000977,52.834396 
	C194.865387,51.254448 193.918457,49.487251 192.564941,48.123615 
	C179.533081,34.994347 166.391708,21.973549 153.394135,8.810667 
	C151.332764,6.723075 149.830887,4.083025 148.035156,1.349200 
	C152.024551,1.000000 156.049088,1.000000 160.540771,1.365257 
	C162.034592,3.349621 162.808701,5.219032 164.126907,6.549257 
	C177.905716,20.453693 191.790207,34.253353 205.584457,48.142593 
	C206.931885,49.499302 207.872147,51.260387 208.542358,52.901638 
	C204.055878,52.924465 200.028427,52.879429 196.000977,52.834396 
z"/>
      <path fill="#D0138F" opacity="1.000000" stroke="none"
        d="
M0.999999,224.449982 
	C2.359444,224.859207 3.934803,225.501724 5.046554,226.609497 
	C28.608435,250.087402 52.123074,273.612762 75.619499,297.156219 
	C76.530022,298.068573 77.167915,299.252960 77.966423,300.655304 
	C75.299988,301.000000 72.599968,301.000000 69.449974,301.000000 
	C68.260910,299.761078 67.738342,298.306519 66.750839,297.315002 
	C46.032990,276.513031 25.267439,255.758530 4.484065,235.021973 
	C3.691479,234.231186 2.627073,233.712845 1.344666,233.033783 
	C1.000000,230.299988 1.000000,227.599976 0.999999,224.449982 
z"/>
      <path fill="#A03CA5" opacity="1.000000" stroke="none"
        d="
M203.000000,301.000000 
	C200.957687,301.000000 198.915359,301.000000 196.402740,300.655334 
	C194.695450,298.780884 193.584732,297.125092 192.202698,295.740051 
	C168.444901,271.930084 144.648697,248.158447 120.882172,224.357162 
	C119.483711,222.956665 118.265335,221.376358 117.089653,219.589081 
	C118.465302,218.159119 119.714462,217.019791 121.077606,216.198700 
	C147.374588,242.758163 173.578201,268.978973 199.687195,295.293640 
	C201.186035,296.804260 201.913528,299.080231 203.000000,301.000000 
z"/>
      <path fill="#9646AA" opacity="1.000000" stroke="none"
        d="
M145.999298,213.166443 
	C147.130173,214.741531 148.069061,216.508057 149.420349,217.863388 
	C175.102798,243.622757 200.848206,269.319366 226.516815,295.092499 
	C227.956497,296.538025 228.846359,298.531189 229.996063,300.634735 
	C227.958328,301.000000 225.916672,301.000000 223.433533,300.634766 
	C221.846252,298.531250 220.956329,296.538147 219.516617,295.092590 
	C193.848053,269.319427 168.102676,243.622787 142.420258,217.863373 
	C141.068954,216.508026 140.130005,214.741531 139.432709,213.098663 
	C141.910660,213.076065 143.954971,213.121262 145.999298,213.166443 
z"/>
      <path fill="#9843A9" opacity="1.000000" stroke="none"
        d="
M0.999999,78.437500 
	C2.709997,79.209999 4.635118,80.204140 6.098036,81.662056 
	C31.976656,107.452263 57.793205,133.304749 83.649483,159.117401 
	C85.036583,160.502151 86.655540,161.654678 88.567108,163.382874 
	C88.922890,165.567291 88.878120,167.284103 88.492088,168.958099 
	C86.145592,167.488510 83.892593,166.310593 82.172310,164.597641 
	C56.788288,139.321854 31.487480,113.962486 6.113029,88.677048 
	C4.645021,87.214188 2.715005,86.214981 1.000001,85.000000 
	C1.000000,82.958336 1.000000,80.916664 0.999999,78.437500 
z"/>
      <path fill="#9843A9" opacity="1.000000" stroke="none"
        d="
M138.999100,213.166443 
	C140.130005,214.741531 141.068954,216.508026 142.420258,217.863373 
	C168.102676,243.622787 193.848053,269.319427 219.516617,295.092590 
	C220.956329,296.538147 221.846252,298.531250 222.996033,300.634766 
	C221.285645,301.000000 219.571289,301.000000 217.394684,300.655334 
	C215.694641,298.780090 214.583267,297.123291 213.200317,295.737549 
	C187.896927,270.382904 162.537201,245.084412 137.282242,219.681702 
	C135.486725,217.875656 134.410004,215.355011 133.423737,213.098846 
	C135.565308,213.076172 137.282211,213.121307 138.999100,213.166443 
z"/>
      <path fill="#9D3FA7" opacity="1.000000" stroke="none"
        d="
M203.437485,301.000000 
	C201.913528,299.080231 201.186035,296.804260 199.687195,295.293640 
	C173.578201,268.978973 147.374588,242.758163 121.223495,215.887482 
	C122.836639,214.560791 124.417892,213.863541 126.041916,213.499817 
	C127.346146,215.345261 128.499466,216.965179 129.885101,218.353180 
	C155.478989,243.990845 181.121872,269.579681 206.662827,295.269867 
	C208.170792,296.786652 208.905472,299.072205 210.000000,301.000000 
	C207.958328,301.000000 205.916641,301.000000 203.437485,301.000000 
z"/>
      <path fill="#A538A3" opacity="1.000000" stroke="none"
        d="
M180.449982,301.000000 
	C178.739868,298.907654 177.839050,296.457367 176.165237,294.777130 
	C154.007721,272.534668 131.741531,250.400421 109.324257,227.807663 
	C110.390991,226.237747 111.635979,225.099335 113.191437,224.100037 
	C138.041718,248.700577 162.589340,273.154236 187.089630,297.655212 
	C187.965485,298.531097 188.373795,299.874481 189.000000,301.000000 
	C186.299988,301.000000 183.599976,301.000000 180.449982,301.000000 
z"/>
      <path fill="#4A87CD" opacity="1.000000" stroke="none"
        d="
M302.000000,169.000000 
	C302.000000,172.362106 302.000000,175.724197 301.634705,179.539124 
	C299.652435,178.967377 297.767181,178.206100 296.461853,176.875763 
	C287.661896,167.907272 279.075897,158.728119 270.239807,149.796021 
	C262.914948,142.391571 255.342285,135.232285 247.928558,127.558105 
	C248.373856,124.767670 248.769974,122.383522 249.521057,120.004791 
	C253.134933,122.724419 256.638672,125.196281 259.608612,128.196533 
	C270.139008,138.834320 280.378754,149.761063 290.968842,160.337860 
	C294.253357,163.618271 298.301483,166.134125 302.000000,169.000000 
z"/>
      <path fill="#349BD8" opacity="1.000000" stroke="none"
        d="
M249.166565,60.998405 
	C250.956024,62.010647 253.072998,62.691513 254.484360,64.086334 
	C268.752808,78.187759 282.894775,92.417046 297.110291,106.572227 
	C298.373352,107.829948 299.904175,108.818779 301.655334,109.966248 
	C302.000000,113.361290 302.000000,116.722572 301.655334,120.508102 
	C299.904236,119.818626 298.373474,118.829781 297.110382,117.572067 
	C282.894989,103.417023 268.753174,89.187897 254.484879,75.086617 
	C253.073608,73.691849 250.956741,73.011040 249.100006,71.544945 
	C249.077286,67.726822 249.121918,64.362617 249.166565,60.998405 
z"/>
      <path fill="#A03CA5" opacity="1.000000" stroke="none"
        d="
M1.000000,100.428261 
	C2.455261,100.622345 4.272240,100.882027 5.312913,101.920036 
	C31.854918,128.393570 58.315689,154.948547 84.798203,182.082611 
	C83.910202,183.463242 83.014626,184.250580 81.808533,184.899078 
	C80.165955,183.507416 78.799767,182.288742 77.506927,180.996674 
	C53.713615,157.217209 29.942291,133.415710 6.110628,109.674782 
	C4.643149,108.212891 2.714224,107.214203 1.000000,106.000000 
	C1.000000,104.285507 1.000000,102.571014 1.000000,100.428261 
z"/>
      <path fill="#5182CA" opacity="1.000000" stroke="none"
        d="
M164.118454,60.037895 
	C161.796219,57.909172 159.393600,55.861057 157.164597,53.638805 
	C141.232742,37.755283 125.326263,21.846224 109.447525,5.909599 
	C108.182877,4.640337 107.187859,3.102411 106.033768,1.344669 
	C109.030594,1.000000 112.061188,1.000000 115.579689,1.344662 
	C117.184494,3.098969 118.176201,4.633333 119.437340,5.899282 
	C134.803619,21.324108 150.226227,36.692867 165.576019,52.134010 
	C166.926254,53.492275 167.867172,55.257408 168.701157,56.982170 
	C166.975571,58.099819 165.547012,59.068859 164.118454,60.037895 
z"/>
      <path fill="#6372C2" opacity="1.000000" stroke="none"
        d="
M69.555267,1.000001 
	C71.095016,2.928339 71.831039,5.213215 73.338936,6.731517 
	C95.835777,29.383684 118.436577,51.932606 141.004333,74.514397 
	C142.294403,75.805283 143.511124,77.169479 144.780334,79.087936 
	C143.905350,80.463585 143.012009,81.250839 141.808182,81.899200 
	C115.621841,55.962875 89.738632,30.172800 63.901829,4.336316 
	C63.028801,3.463300 62.623379,2.122668 62.000000,1.000000 
	C64.370178,1.000000 66.740349,1.000000 69.555267,1.000001 
z"/>
      <path fill="#A33AA4" opacity="1.000000" stroke="none"
        d="
M1.000000,106.437500 
	C2.714224,107.214203 4.643149,108.212891 6.110628,109.674782 
	C29.942291,133.415710 53.713615,157.217209 77.506927,180.996674 
	C78.799767,182.288742 80.165955,183.507416 81.672226,185.194305 
	C80.577011,186.792282 79.307571,187.956207 78.038132,189.120132 
	C77.206215,188.094223 76.458466,186.984283 75.530098,186.054718 
	C52.105049,162.598404 28.666582,139.155457 5.201735,115.738976 
	C4.169888,114.709251 2.866331,113.951813 1.344670,113.033768 
	C1.000000,110.958336 1.000000,108.916664 1.000000,106.437500 
z"/>
      <path fill="#A33AA4" opacity="1.000000" stroke="none"
        d="
M189.437500,301.000000 
	C188.373795,299.874481 187.965485,298.531097 187.089630,297.655212 
	C162.589340,273.154236 138.041718,248.700577 113.327103,223.804535 
	C114.422600,222.206528 115.692886,221.043121 116.963165,219.879715 
	C118.265335,221.376358 119.483711,222.956665 120.882172,224.357162 
	C144.648697,248.158447 168.444901,271.930084 192.202698,295.740051 
	C193.584732,297.125092 194.695450,298.780884 195.966217,300.655334 
	C193.958328,301.000000 191.916672,301.000000 189.437500,301.000000 
z"/>
      <path fill="#6372C2" opacity="1.000000" stroke="none"
        d="
M302.000000,236.750000 
	C301.655029,238.086349 301.310059,239.172714 300.566650,240.587250 
	C298.656128,239.654282 297.036041,238.501328 295.648041,237.115875 
	C271.536407,213.048828 247.457993,188.948517 223.351883,164.875946 
	C221.953400,163.479416 220.380447,162.257614 219.025330,160.661438 
	C220.423721,159.211411 221.685516,158.054459 222.947311,156.897522 
	C224.134857,158.268265 225.243698,159.717560 226.521759,160.997940 
	C245.402481,179.912933 264.339355,198.772003 283.180603,217.726151 
	C288.078094,222.652969 292.659027,227.893204 297.464569,232.913666 
	C298.580170,234.079163 300.020020,234.934311 301.655334,235.966125 
	C302.000000,236.000000 302.000000,236.500000 302.000000,236.750000 
z"/>
      <path fill="#468BCF" opacity="1.000000" stroke="none"
        d="
M302.000000,168.545898 
	C298.301483,166.134125 294.253357,163.618271 290.968842,160.337860 
	C280.378754,149.761063 270.139008,138.834320 259.608612,128.196533 
	C256.638672,125.196281 253.134933,122.724419 249.454285,119.550751 
	C249.077377,115.726685 249.122223,112.362099 249.167053,108.997505 
	C250.506821,109.893539 252.034729,110.600052 253.157547,111.714638 
	C265.649017,124.114372 278.047485,136.607834 290.543274,149.003159 
	C294.004120,152.436188 297.704041,155.628220 301.647125,158.965424 
	C302.000000,162.030594 302.000000,165.061188 302.000000,168.545898 
z"/>
      <path fill="#5182CA" opacity="1.000000" stroke="none"
        d="
M302.000000,195.550018 
	C298.552338,193.384033 294.777710,191.099396 291.723206,188.085007 
	C284.779510,181.232437 278.290924,173.921204 271.431488,166.980087 
	C261.877350,157.312164 252.153320,147.812119 242.334000,137.797943 
	C243.166122,135.904297 244.166275,134.452942 245.499969,133.043579 
	C247.227203,134.228607 248.727814,135.264404 249.998367,136.530960 
	C265.759064,152.241913 281.468201,168.004669 297.254608,183.689682 
	C298.602295,185.028732 300.407379,185.907440 302.000000,187.000000 
	C302.000000,189.700012 302.000000,192.400024 302.000000,195.550018 
z"/>
      <path fill="#9B41A8" opacity="1.000000" stroke="none"
        d="
M210.437500,301.000000 
	C208.905472,299.072205 208.170792,296.786652 206.662827,295.269867 
	C181.121872,269.579681 155.478989,243.990845 129.885101,218.353180 
	C128.499466,216.965179 127.346146,215.345261 126.475510,213.431946 
	C128.910568,213.075928 130.954819,213.121292 132.999054,213.166641 
	C134.410004,215.355011 135.486725,217.875656 137.282242,219.681702 
	C162.537201,245.084412 187.896927,270.382904 213.200317,295.737549 
	C214.583267,297.123291 215.694641,298.780090 216.966217,300.655334 
	C214.958328,301.000000 212.916672,301.000000 210.437500,301.000000 
z"/>
      <path fill="#B22D9D" opacity="1.000000" stroke="none"
        d="
M155.000000,301.000000 
	C152.299377,301.000000 149.598740,301.000000 146.415344,300.655334 
	C144.819687,298.905029 143.831482,297.375275 142.574738,296.112854 
	C129.015137,282.491882 115.394287,268.931885 101.836288,255.309296 
	C98.460052,251.917007 95.249588,248.359711 92.084862,244.590240 
	C93.431480,243.188309 94.656174,242.074356 96.191315,241.099609 
	C115.375572,260.034241 134.259506,278.819580 153.091171,297.657166 
	C153.966354,298.532623 154.374390,299.875061 155.000000,301.000000 
z"/>
      <path fill="#3B94D4" opacity="1.000000" stroke="none"
        d="
M209.001404,52.833771 
	C207.872147,51.260387 206.931885,49.499302 205.584457,48.142593 
	C191.790207,34.253353 177.905716,20.453693 164.126907,6.549257 
	C162.808701,5.219032 162.034592,3.349621 161.003952,1.365257 
	C164.030594,1.000000 167.061188,1.000000 170.545883,1.000003 
	C171.975922,2.808916 172.596741,4.968333 173.982727,6.372479 
	C187.745026,20.315075 201.643555,34.123108 215.470474,48.002148 
	C216.736481,49.272926 217.773148,50.772175 218.509354,52.566944 
	C215.068741,52.923630 212.035065,52.878700 209.001404,52.833771 
z"/>
      <path fill="#676FC0" opacity="1.000000" stroke="none"
        d="
M52.000343,2.166002 
	C52.295845,1.957497 52.591347,1.748993 52.943424,1.270244 
	C54.066788,1.000000 55.133575,1.000000 56.604137,1.365259 
	C58.157459,3.472531 59.051262,5.469214 60.494606,6.918673 
	C85.401619,31.931187 110.372429,56.880203 135.348526,81.823845 
	C136.513321,82.987122 137.857651,83.970627 138.989639,85.320694 
	C137.941864,86.419556 137.022827,87.235588 135.793488,87.911743 
	C108.315895,60.674702 81.135490,33.590611 54.030773,6.430986 
	C52.982224,5.380314 52.660660,3.604124 52.000343,2.166002 
z"/>
      <path fill="#B02F9E" opacity="1.000000" stroke="none"
        d="
M155.437500,301.000000 
	C154.374390,299.875061 153.966354,298.532623 153.091171,297.657166 
	C134.259506,278.819580 115.375572,260.034241 96.322983,240.807068 
	C97.390038,239.237198 98.635880,238.099060 100.192078,237.100128 
	C120.377762,257.036682 140.272064,276.815125 160.074890,296.684692 
	C161.113876,297.727203 161.376129,299.543793 162.000000,301.000000 
	C159.958328,301.000000 157.916672,301.000000 155.437500,301.000000 
z"/>
      <path fill="#6570C1" opacity="1.000000" stroke="none"
        d="
M139.118378,85.037857 
	C137.857651,83.970627 136.513321,82.987122 135.348526,81.823845 
	C110.372429,56.880203 85.401619,31.931187 60.494606,6.918673 
	C59.051262,5.469214 58.157459,3.472531 57.003956,1.365259 
	C58.388889,1.000000 59.777779,1.000000 61.583336,1.000000 
	C62.623379,2.122668 63.028801,3.463300 63.901829,4.336316 
	C89.738632,30.172800 115.621841,55.962875 141.679932,82.181335 
	C140.947586,83.414192 140.032974,84.226028 139.118378,85.037857 
z"/>
      <path fill="#3699D6" opacity="1.000000" stroke="none"
        d="
M249.167358,71.998856 
	C250.956741,73.011040 253.073608,73.691849 254.484879,75.086617 
	C268.753174,89.187897 282.894989,103.417023 297.110382,117.572067 
	C298.373474,118.829781 299.904236,119.818626 301.655334,120.966171 
	C302.000000,123.700012 302.000000,126.400024 302.000000,129.550018 
	C300.410095,128.910065 298.608704,128.033218 297.262878,126.697380 
	C283.028381,112.568405 268.888031,98.344536 254.649078,84.220108 
	C253.312195,82.893982 251.479431,82.067757 249.455246,80.557732 
	C249.078171,77.403633 249.122772,74.701241 249.167358,71.998856 
z"/>
      <path fill="#B22D9D" opacity="1.000000" stroke="none"
        d="
M0.999999,155.000000 
	C1.000000,152.299377 1.000000,149.598740 1.344663,146.482849 
	C3.213928,147.299423 4.863300,148.406097 6.244161,149.782181 
	C23.166019,166.645630 40.040630,183.556458 56.953888,200.428543 
	C58.584969,202.055634 60.392696,203.505630 61.986385,205.330200 
	C60.609150,206.760971 59.363960,207.899368 57.808250,208.898941 
	C40.588337,191.929932 23.710161,175.068054 6.716061,158.323822 
	C5.195366,156.825485 2.923178,156.089859 0.999999,155.000000 
z"/>
      <path fill="#AD319F" opacity="1.000000" stroke="none"
        d="
M162.444168,301.000000 
	C161.376129,299.543793 161.113876,297.727203 160.074890,296.684692 
	C140.272064,276.815125 120.377762,257.036682 100.326645,236.804047 
	C101.421623,235.205261 102.692390,234.041748 103.963150,232.878250 
	C105.141899,234.251831 106.236877,235.709015 107.511925,236.986450 
	C127.073914,256.584503 146.668198,276.150299 166.220398,295.758087 
	C167.596100,297.137665 168.701492,298.786835 169.966278,300.655334 
	C167.629440,301.000000 165.258896,301.000000 162.444168,301.000000 
z"/>
      <path fill="#9348AB" opacity="1.000000" stroke="none"
        d="
M88.833275,155.000732 
	C87.253998,153.865677 85.483383,152.922531 84.123810,151.567093 
	C58.759075,126.279686 33.462364,100.924026 8.077036,75.657372 
	C6.245227,73.834129 3.842240,72.584747 1.352411,71.034271 
	C1.000000,69.285645 1.000000,67.571281 1.000000,65.428467 
	C2.710503,66.210373 4.636138,67.204865 6.099534,68.663208 
	C31.751434,94.226410 57.333519,119.859673 82.989250,145.419022 
	C84.443413,146.867722 86.400948,147.811188 88.545975,149.420151 
	C88.922928,151.567245 88.878098,153.283997 88.833275,155.000732 
z"/>
      <path fill="#9B41A8" opacity="1.000000" stroke="none"
        d="
M1.000001,85.416664 
	C2.715005,86.214981 4.645021,87.214188 6.113029,88.677048 
	C31.487480,113.962486 56.788288,139.321854 82.172310,164.597641 
	C83.892593,166.310593 86.145592,167.488510 88.559265,169.382843 
	C88.922905,171.567261 88.878128,173.284058 88.833344,175.000870 
	C87.258705,173.870422 85.492630,172.931976 84.137756,171.581116 
	C58.151859,145.671951 32.220963,119.707642 6.247975,93.785507 
	C4.867064,92.407310 3.215089,91.300705 1.344652,90.033829 
	C1.000000,88.611107 1.000000,87.222221 1.000001,85.416664 
z"/>
      <path fill="#8E4CAE" opacity="1.000000" stroke="none"
        d="
M88.833237,140.998367 
	C87.258034,139.868149 85.491066,138.930267 84.136009,137.579254 
	C58.376759,111.897041 32.680359,86.151764 6.907437,60.483326 
	C5.461884,59.043636 3.468776,58.153732 1.365259,57.003967 
	C1.000000,55.933331 1.000000,54.866661 1.000000,53.399994 
	C1.000000,53.000000 1.440243,52.941151 2.035366,52.964806 
	C4.383481,54.122139 6.402841,54.988308 7.849897,56.429226 
	C33.588722,82.058762 59.245079,107.771118 84.976143,133.408478 
	C86.441193,134.868195 88.395096,135.837265 90.071625,137.389801 
	C89.625595,138.829422 89.229416,139.913895 88.833237,140.998367 
z"/>
      <path fill="#8E4CAE" opacity="1.000000" stroke="none"
        d="
M249.042831,300.115234 
	C249.042831,300.115234 249.007278,300.556946 249.003632,300.778473 
	C247.933212,301.000000 246.866425,301.000000 245.395844,300.634735 
	C243.846237,298.531250 242.956299,296.538208 241.516617,295.092651 
	C215.848190,269.319733 190.102936,243.623306 164.420746,217.864029 
	C163.069748,216.508972 162.131912,214.741989 161.321625,213.005402 
	C162.749069,212.522614 163.856598,212.201202 165.003296,212.232910 
	C166.262619,214.125046 167.345154,215.801437 168.723404,217.182755 
	C194.001099,242.516235 219.341919,267.786896 244.564148,293.175354 
	C246.462311,295.086029 247.568497,297.783508 249.042831,300.115234 
z"/>
      <path fill="#4D85CC" opacity="1.000000" stroke="none"
        d="
M168.998199,56.833565 
	C167.867172,55.257408 166.926254,53.492275 165.576019,52.134010 
	C150.226227,36.692867 134.803619,21.324108 119.437340,5.899282 
	C118.176201,4.633333 117.184494,3.098969 116.033798,1.344662 
	C118.700012,1.000000 121.400032,1.000000 124.584305,1.352409 
	C126.461227,3.718706 127.592049,5.991688 129.287918,7.705563 
	C143.069107,21.633137 156.977890,35.434391 170.776062,49.345276 
	C172.110794,50.690903 172.959976,52.518112 173.874146,54.409195 
	C172.141907,55.408939 170.570053,56.121254 168.998199,56.833565 
z"/>
      <path fill="#904BAD" opacity="1.000000" stroke="none"
        d="
M161.001724,213.166779 
	C162.131912,214.741989 163.069748,216.508972 164.420746,217.864029 
	C190.102936,243.623306 215.848190,269.319733 241.516617,295.092651 
	C242.956299,296.538208 243.846237,298.531250 244.996017,300.634735 
	C243.933334,301.000000 242.866653,301.000000 241.399994,301.000000 
	C239.789459,299.289551 238.794708,297.364105 237.336304,295.900635 
	C211.773407,270.249084 186.140472,244.667297 160.581436,219.011917 
	C159.132660,217.557693 158.188644,215.600616 157.401047,213.455017 
	C158.862167,213.077240 159.931946,213.122009 161.001724,213.166779 
z"/>
      <path fill="#A935A1" opacity="1.000000" stroke="none"
        d="
M70.037697,197.118759 
	C69.092278,195.979706 68.230545,194.757111 67.189079,193.713943 
	C47.000240,173.491760 26.806904,153.273941 6.547017,133.123077 
	C5.221047,131.804230 3.352365,131.031006 1.368061,130.001999 
	C1.000000,128.285553 1.000000,126.571091 1.000000,124.428314 
	C2.456633,124.623695 4.274313,124.885201 5.316777,125.924370 
	C27.858116,148.394501 50.312950,170.951401 72.790848,194.089691 
	C71.877373,195.498154 70.957535,196.308456 70.037697,197.118759 
z"/>
      <path fill="#4A87CD" opacity="1.000000" stroke="none"
        d="
M174.034546,54.121765 
	C172.959976,52.518112 172.110794,50.690903 170.776062,49.345276 
	C156.977890,35.434391 143.069107,21.633137 129.287918,7.705563 
	C127.592049,5.991688 126.461227,3.718706 125.034279,1.352409 
	C127.041664,1.000000 129.083328,1.000000 131.562500,1.000001 
	C133.091507,2.591429 133.970108,4.394122 135.307343,5.741693 
	C149.099380,19.640287 162.987854,33.443180 176.775284,47.346313 
	C178.102737,48.684917 178.930481,50.519077 179.615372,52.558495 
	C177.505219,53.369781 175.769897,53.745773 174.034546,54.121765 
z"/>
      <path fill="#B62A9B" opacity="1.000000" stroke="none"
        d="
M0.999999,155.428467 
	C2.923178,156.089859 5.195366,156.825485 6.716061,158.323822 
	C23.710161,175.068054 40.588337,191.929932 57.673576,209.194656 
	C56.580956,210.789841 55.312489,211.950516 54.044025,213.111176 
	C49.319382,208.197571 44.659344,203.219833 39.857136,198.383255 
	C28.583912,187.029419 17.256468,175.729233 5.909763,164.448792 
	C4.638738,163.185196 3.102503,162.188385 1.344629,161.033920 
	C1.000000,159.285645 1.000000,157.571289 0.999999,155.428467 
z"/>
      <path fill="#4D85CC" opacity="1.000000" stroke="none"
        d="
M302.000000,186.562500 
	C300.407379,185.907440 298.602295,185.028732 297.254608,183.689682 
	C281.468201,168.004669 265.759064,152.241913 249.998367,136.530960 
	C248.727814,135.264404 247.227203,134.228607 245.536041,132.714127 
	C246.118851,130.883255 246.999130,129.423828 247.879395,127.964401 
	C255.342285,135.232285 262.914948,142.391571 270.239807,149.796021 
	C279.075897,158.728119 287.661896,167.907272 296.461853,176.875763 
	C297.767181,178.206100 299.652435,178.967377 301.634705,179.995972 
	C302.000000,182.041672 302.000000,184.083328 302.000000,186.562500 
z"/>
      <path fill="#B02F9E" opacity="1.000000" stroke="none"
        d="
M62.118431,205.037827 
	C60.392696,203.505630 58.584969,202.055634 56.953888,200.428543 
	C40.040630,183.556458 23.166019,166.645630 6.244161,149.782181 
	C4.863300,148.406097 3.213928,147.299423 1.344663,146.033783 
	C1.000000,144.611115 1.000000,143.222214 0.999999,141.416656 
	C2.931650,142.098312 5.214524,142.840927 6.741654,144.348892 
	C26.057158,163.421921 45.263832,182.605179 64.683517,202.182831 
	C63.951988,203.416199 63.035210,204.227005 62.118431,205.037827 
z"/>
      <path fill="#B62A9B" opacity="1.000000" stroke="none"
        d="
M91.962929,244.878250 
	C95.249588,248.359711 98.460052,251.917007 101.836288,255.309296 
	C115.394287,268.931885 129.015137,282.491882 142.574738,296.112854 
	C143.831482,297.375275 144.819687,298.905029 145.966278,300.655334 
	C144.285645,301.000000 142.571289,301.000000 140.428467,301.000000 
	C138.905792,299.405884 138.024689,297.600250 136.684570,296.250214 
	C122.395180,281.855621 108.023552,267.542694 93.704826,253.177139 
	C92.189484,251.656860 90.867210,249.944138 89.299164,247.848068 
	C90.082947,246.543045 91.022942,245.710648 91.962929,244.878250 
z"/>
      <path fill="#349BD8" opacity="1.000000" stroke="none"
        d="
M232.000824,52.833267 
	C230.987137,51.042133 230.304626,48.923813 228.908463,47.510521 
	C215.144424,33.577705 201.250534,19.773264 187.432129,5.893935 
	C186.173065,4.629332 185.182693,3.097221 184.033783,1.344667 
	C186.041672,1.000000 188.083328,1.000000 190.562500,1.000001 
	C191.975830,2.808920 192.596542,4.968367 193.982498,6.372480 
	C207.744797,20.315100 221.643356,34.123146 235.470306,48.002197 
	C236.736313,49.272976 237.772919,50.772278 238.525467,52.567142 
	C236.090195,52.923637 234.045517,52.878452 232.000824,52.833267 
z"/>
      <path fill="#FFFFFF" opacity="1.000000" stroke="none"
        d="
M231.541870,52.901287 
	C234.045517,52.878452 236.090195,52.923637 238.568573,52.900574 
	C242.127930,52.832329 245.253601,52.832329 249.032608,52.832329 
	C249.032608,55.586361 249.032608,57.844872 249.099579,60.550896 
	C249.121918,64.362617 249.077286,67.726822 249.100006,71.544945 
	C249.122772,74.701241 249.078171,77.403633 249.100327,80.551941 
	C249.122375,85.025322 249.077682,89.052780 249.099899,93.539185 
	C249.122360,98.690071 249.077896,103.382004 249.100250,108.535721 
	C249.122223,112.362099 249.077377,115.726685 249.099304,119.545319 
	C248.769974,122.383522 248.373856,124.767670 247.928574,127.558121 
	C246.999130,129.423828 246.118851,130.883255 245.202515,132.672134 
	C244.166275,134.452942 243.166122,135.904297 242.023468,137.659210 
	C239.354050,139.740341 236.827179,141.517929 234.132874,143.586914 
	C231.891922,144.595932 229.818375,145.313553 227.372345,146.098328 
	C222.306381,146.454742 217.612885,146.744019 212.001038,147.032944 
	C207.055817,147.077301 203.028946,147.122009 198.559265,147.100403 
	C194.727554,147.654236 191.338638,148.274399 187.646057,149.039734 
	C185.203430,151.100128 183.064484,153.015335 179.916977,155.833618 
	C185.495804,155.833618 189.247467,155.833618 193.453079,155.900330 
	C197.271622,155.922485 200.636230,155.877960 204.459717,155.900192 
	C208.945724,155.922485 212.972855,155.878006 216.999985,155.833527 
	C218.848495,155.963989 220.697021,156.094467 222.746429,156.561234 
	C221.685516,158.054459 220.423721,159.211411 218.762756,160.766754 
	C217.893295,161.740524 217.423035,162.315887 216.670502,163.018250 
	C215.576508,164.060425 214.764771,164.975616 213.662476,166.025116 
	C212.232330,167.403275 211.092758,168.647141 209.653366,170.033295 
	C207.867874,171.433060 206.382202,172.690552 204.592346,174.086441 
	C202.824295,175.132675 201.360413,176.040512 199.482971,176.972229 
	C195.713699,177.386078 192.357971,177.776047 188.551788,178.099487 
	C185.068146,178.077316 182.034958,178.121719 178.540985,178.099411 
	C173.720169,178.077316 169.360138,178.121933 164.580383,178.100983 
	C160.295898,177.510223 156.911270,178.120438 154.583145,182.100098 
	C152.771561,183.818756 151.267258,185.392960 148.936325,187.832214 
	C153.554718,187.832214 156.775665,187.832214 160.450562,187.899796 
	C164.269409,187.922806 167.634277,187.878235 171.459473,187.901245 
	C176.280045,187.923965 180.640305,187.879074 185.416946,187.900757 
	C187.517380,187.992218 189.201416,188.017120 190.846954,188.335358 
	C190.190399,189.383438 189.572342,190.138153 188.663147,191.025970 
	C187.232513,192.403061 186.093033,193.647049 184.655884,195.030853 
	C182.868698,196.765213 181.379166,198.359711 179.602539,200.081299 
	C178.194763,201.436264 177.074097,202.664154 175.647369,204.038742 
	C172.610748,206.322159 169.880173,208.458893 166.915100,210.722733 
	C166.108429,211.193161 165.536285,211.536484 164.964142,211.879791 
	C163.856598,212.201202 162.749069,212.522614 161.321625,213.005402 
	C159.931946,213.122009 158.862167,213.077240 157.395416,213.100143 
	C153.634689,213.122162 150.270935,213.076492 146.453232,213.098633 
	C143.954971,213.121262 141.910660,213.076065 139.432709,213.098663 
	C137.282211,213.121307 135.565308,213.076172 133.423737,213.098846 
	C130.954819,213.121292 128.910568,213.075928 126.432739,213.098434 
	C124.417892,213.863541 122.836639,214.560791 121.109505,215.569244 
	C119.714462,217.019791 118.465302,218.159119 117.089653,219.589081 
	C115.692886,221.043121 114.422600,222.206528 113.016647,223.665436 
	C111.635979,225.099335 110.390991,226.237747 109.013885,227.668427 
	C107.327393,229.404465 105.773018,230.848267 104.090897,232.585144 
	C102.692390,234.041748 101.421623,235.205261 100.016296,236.664825 
	C98.635880,238.099060 97.390038,239.237198 96.012527,240.667877 
	C94.656174,242.074356 93.431480,243.188309 92.084862,244.590240 
	C91.022942,245.710648 90.082947,246.543045 89.015999,247.665619 
	C87.916100,247.459213 86.739944,247.167694 86.000839,246.435181 
	C75.620232,236.147156 65.294800,225.803421 54.978981,215.450272 
	C54.545719,215.015442 54.336685,214.357178 54.033600,213.457092 
	C55.312489,211.950516 56.580956,210.789841 57.984093,209.333466 
	C59.363960,207.899368 60.609150,206.760971 61.986389,205.330200 
	C63.035210,204.227005 63.951988,203.416199 64.994095,202.321625 
	C66.673096,200.593430 68.226776,199.148956 69.909073,197.411621 
	C70.957535,196.308456 71.877373,195.498154 72.918221,194.404053 
	C74.618347,192.649872 76.197464,191.179474 77.907364,189.414612 
	C79.307571,187.956207 80.577011,186.792282 81.982758,185.333130 
	C83.014626,184.250580 83.910202,183.463242 84.922089,182.397995 
	C86.347710,179.997147 87.657021,177.874222 88.899841,175.376083 
	C88.878128,173.284058 88.922905,171.567261 88.900513,169.425690 
	C88.878120,167.284103 88.922890,165.567291 88.900612,163.425674 
	C88.878197,160.627670 88.922836,158.254471 88.900375,155.440994 
	C88.878098,153.283997 88.922928,151.567245 88.900955,149.425705 
	C88.878616,146.626999 88.923073,144.253113 88.900383,141.438782 
	C89.229416,139.913895 89.625595,138.829422 90.361786,137.227951 
	C91.169823,135.490601 91.637840,134.270233 92.408066,132.909180 
	C94.174843,130.862747 95.639397,128.956970 97.397362,126.919472 
	C99.144051,125.226845 100.597328,123.665955 102.346436,121.967659 
	C104.111641,120.255081 105.581009,118.679909 107.343803,116.969330 
	C108.795128,115.571129 109.953033,114.308334 111.383652,112.931778 
	C112.424347,112.251137 113.192329,111.684258 113.976288,111.537399 
	C114.050117,113.638596 114.107964,115.319778 114.099121,117.461548 
	C114.077065,122.282188 114.121704,126.642235 114.100052,131.456390 
	C114.078430,135.274719 114.123108,138.638977 114.101349,142.448608 
	C114.034920,145.871063 114.034920,148.848129 114.034920,151.825180 
	C114.431961,151.956192 114.829010,152.087189 115.226051,152.218185 
	C116.832626,150.514801 118.439194,148.811417 120.369049,146.947693 
	C124.423615,144.386932 124.373528,140.772781 123.901077,136.540985 
	C123.923759,131.719772 123.878822,127.358887 123.902069,122.540878 
	C123.924957,118.055794 123.879662,114.027832 123.900406,109.590790 
	C124.351326,106.799576 124.736191,104.417442 125.409973,101.871887 
	C126.167915,100.488991 126.636932,99.269516 127.409515,97.906372 
	C129.511963,95.523666 131.310867,93.284630 133.392151,90.923325 
	C134.484268,89.884575 135.294022,88.968094 136.103775,88.051620 
	C137.022827,87.235588 137.941864,86.419556 138.989639,85.320694 
	C140.032974,84.226028 140.947586,83.414192 141.990417,82.320221 
	C143.012009,81.250839 143.905350,80.463585 144.916504,79.399673 
	C145.367599,79.858147 145.700897,80.593277 146.033478,82.120972 
	C146.077362,86.608467 146.121964,90.303406 146.099747,94.452576 
	C146.077332,98.272011 146.121719,101.637222 146.099548,105.461227 
	C146.077484,109.946922 146.121964,113.973816 146.099838,118.457230 
	C146.077667,122.609863 146.122116,126.305977 146.100128,130.458649 
	C146.078064,134.610596 146.122421,138.305954 146.100220,142.436722 
	C146.005325,145.213623 145.976974,147.555130 145.645569,150.040924 
	C142.524567,153.108215 139.706619,156.031219 136.599228,159.083466 
	C135.172028,160.457932 134.034256,161.703125 132.597260,163.086212 
	C130.161591,165.467316 128.025116,167.710526 125.625664,170.058533 
	C124.892899,170.739288 124.423119,171.315216 123.670486,172.017151 
	C122.576187,173.058899 121.764740,173.974655 120.655182,175.029495 
	C118.867340,176.763290 117.377602,178.358002 115.589996,180.088989 
	C114.492363,181.466568 113.692596,182.707886 112.562988,183.978577 
	C111.118019,184.989899 110.002884,185.971863 108.618362,187.063278 
	C107.864586,187.764618 107.380211,188.356506 106.631500,189.052994 
	C105.895981,189.735779 105.424812,190.313980 104.659195,191.026672 
	C103.206772,192.425705 102.048782,193.690262 100.611252,195.072266 
	C99.539177,196.090622 98.746651,196.991562 97.652435,198.032623 
	C95.796341,199.482285 94.241936,200.791809 93.265892,201.614090 
	C95.907753,204.379318 97.878067,206.441650 99.965355,208.626419 
	C101.286591,207.165512 102.698311,205.604553 104.403702,203.913910 
	C105.453796,202.800186 106.210236,201.816132 107.288086,200.815125 
	C108.776131,199.547104 109.942764,198.296021 111.373764,196.941772 
	C112.108841,196.260941 112.579552,195.683273 113.319176,194.993301 
	C114.074791,194.290634 114.561501,193.700287 115.348083,192.970154 
	C116.449341,191.588821 117.250717,190.347260 118.379135,189.055969 
	C119.820358,188.041000 120.934532,187.075775 122.346893,185.971649 
	C124.133759,184.236938 125.622437,182.641129 127.394501,180.923233 
	C128.488739,179.882523 129.299591,178.963867 130.373840,177.941177 
	C131.107605,177.261124 131.577972,176.685089 132.351685,175.965485 
	C134.579849,173.484528 136.504684,171.147125 138.777527,168.822342 
	C140.432968,167.593018 141.740372,166.351059 143.359924,164.956146 
	C146.484863,161.883789 149.297653,158.964401 152.420792,155.897675 
	C156.350708,153.534714 156.108566,150.391495 154.900024,146.543671 
	C154.922501,142.391281 154.877975,138.695267 154.900513,134.542816 
	C154.922913,130.390732 154.878250,126.695099 154.900620,122.540405 
	C154.923065,118.054611 154.878464,114.027878 154.900711,109.547020 
	C154.922882,105.727547 154.878204,102.362213 154.900208,98.540657 
	C154.922363,94.389313 154.877838,90.694176 154.833313,86.999046 
	C154.896805,83.646835 154.960312,80.294624 155.072998,76.488831 
	C156.121292,73.493324 157.120377,70.951401 158.291565,68.123016 
	C160.250015,65.440735 162.036392,63.044910 163.970612,60.343491 
	C165.547012,59.068859 166.975571,58.099819 168.701157,56.982174 
	C170.570053,56.121254 172.141907,55.408939 173.874161,54.409195 
	C175.769897,53.745773 177.505219,53.369781 179.620834,52.913433 
	C185.025970,52.878750 190.050827,52.924419 195.538330,52.902245 
	C200.028427,52.879429 204.055878,52.924465 208.542358,52.901634 
	C212.035065,52.878700 215.068741,52.923630 218.552551,52.900375 
	C223.029419,52.877892 227.056168,52.923599 231.541870,52.901287 
M82.976120,231.473404 
	C84.291641,233.118835 85.607155,234.764267 87.251762,236.821304 
	C89.159012,234.662750 90.346237,233.292007 91.563843,231.948822 
	C92.747192,230.643463 93.961655,229.366302 95.125313,228.116653 
	C87.574608,218.992935 87.142403,218.996368 79.915642,228.186691 
	C80.736595,229.084885 81.602135,230.031860 82.976120,231.473404 
M74.959862,207.495270 
	C71.911232,209.609207 68.862610,211.723160 64.940987,214.442444 
	C67.072418,216.144775 68.520500,217.217850 69.870636,218.402527 
	C71.294182,219.651581 72.624199,221.007233 73.962021,222.284744 
	C84.073280,212.859802 82.163040,215.116623 74.959862,207.495270 
z"/>
      <path fill="#6570C1" opacity="1.000000" stroke="none"
        d="
M216.957458,155.498718 
	C212.972855,155.878006 208.945724,155.922485 204.454224,155.547287 
	C202.327255,152.473984 200.664673,149.820343 199.002075,147.166718 
	C203.028946,147.122009 207.055817,147.077301 211.541931,147.099518 
	C213.639099,149.832260 215.277023,152.498077 216.957458,155.498718 
z"/>
      <path fill="#6372C2" opacity="1.000000" stroke="none"
        d="
M146.166565,93.998337 
	C146.121964,90.303406 146.077362,86.608467 146.099701,82.456909 
	C148.820267,83.663246 151.473907,85.326210 154.480438,86.994110 
	C154.877838,90.694176 154.922363,94.389313 154.565567,98.499069 
	C151.498352,97.275246 148.832458,95.636795 146.166565,93.998337 
z"/>
      <path fill="#7463BA" opacity="1.000000" stroke="none"
        d="
M189.002258,178.166031 
	C192.357971,177.776047 195.713699,177.386078 199.791260,177.117279 
	C218.783081,195.433304 237.060165,213.620987 255.320175,231.825836 
	C265.570740,242.045441 275.776001,252.310547 286.041321,262.515259 
	C288.314667,264.775177 290.755280,266.866821 293.118866,269.035919 
	C291.461945,271.484772 289.804993,273.933624 287.658569,276.648865 
	C286.009613,276.006866 284.741150,275.207581 283.706451,274.174194 
	C253.019653,243.526505 222.348984,212.862625 191.716919,182.160233 
	C190.599808,181.040573 189.897736,179.506821 189.002258,178.166031 
z"/>
      <path fill="#8851B0" opacity="1.000000" stroke="none"
        d="
M167.149597,210.595612 
	C169.880173,208.458893 172.610748,206.322159 175.790344,204.348022 
	C177.489944,205.837494 178.707535,207.197342 179.995956,208.486374 
	C207.108643,235.612030 234.236496,262.722534 261.329498,289.867828 
	C262.834656,291.375885 264.064514,293.158691 265.422516,294.813629 
	C262.198700,296.190491 258.974884,297.567322 255.333176,298.556274 
	C254.241425,297.243561 253.676773,296.209717 252.877914,295.409790 
	C225.507294,268.001373 198.119644,240.609940 170.704926,213.245636 
	C169.669617,212.212219 168.341354,211.472275 167.149597,210.595612 
z"/>
      <path fill="#8058B4" opacity="1.000000" stroke="none"
        d="
M179.889618,199.954208 
	C181.379166,198.359711 182.868698,196.765213 184.798859,195.340271 
	C215.153458,225.497314 245.063705,255.488510 275.001709,285.451965 
	C275.798279,286.249237 276.896027,286.745575 277.853302,287.382263 
	C276.168701,288.584015 274.484100,289.785736 272.143188,290.874542 
	C242.445160,261.795044 213.407227,232.824600 184.349915,203.873611 
	C182.950577,202.479385 181.380295,201.256744 179.889618,199.954208 
z"/>
      <path fill="#8455B2" opacity="1.000000" stroke="none"
        d="
M179.602539,200.081299 
	C181.380295,201.256744 182.950577,202.479385 184.349915,203.873611 
	C213.407227,232.824600 242.445160,261.795044 271.795227,290.906494 
	C270.122101,292.309784 268.140625,293.568146 265.790833,294.820068 
	C264.064514,293.158691 262.834656,291.375885 261.329498,289.867828 
	C234.236496,262.722534 207.108643,235.612030 179.995956,208.486374 
	C178.707535,207.197342 177.489944,205.837494 176.096405,204.201324 
	C177.074097,202.664154 178.194763,201.436264 179.602539,200.081299 
z"/>
      <path fill="#7C5CB6" opacity="1.000000" stroke="none"
        d="
M278.089142,287.230499 
	C276.896027,286.745575 275.798279,286.249237 275.001709,285.451965 
	C245.063705,255.488510 215.153458,225.497314 185.096512,195.200439 
	C186.093033,193.647049 187.232513,192.403061 188.783203,191.343811 
	C218.506561,220.895966 247.822449,250.259674 277.119232,279.642426 
	C278.515869,281.043182 279.743286,282.612640 281.049500,284.103546 
	C280.141327,285.095276 279.233154,286.087006 278.089142,287.230499 
z"/>
      <path fill="#7066BB" opacity="1.000000" stroke="none"
        d="
M293.406128,268.874908 
	C290.755280,266.866821 288.314667,264.775177 286.041321,262.515259 
	C275.776001,252.310547 265.570740,242.045441 255.320175,231.825836 
	C237.060165,213.620987 218.783081,195.433304 200.204819,177.093414 
	C201.360413,176.040512 202.824295,175.132675 204.900650,174.231537 
	C233.810150,202.789963 262.096191,231.352615 290.433472,259.864319 
	C292.016724,261.457306 294.023163,262.629639 295.832642,263.997772 
	C295.119568,265.569824 294.406494,267.141846 293.406128,268.874908 
z"/>
      <path fill="#6D69BD" opacity="1.000000" stroke="none"
        d="
M296.004333,263.666138 
	C294.023163,262.629639 292.016724,261.457306 290.433472,259.864319 
	C262.096191,231.352615 233.810150,202.789963 205.204834,174.093140 
	C206.382202,172.690552 207.867874,171.433060 209.796417,170.342682 
	C238.398987,198.744736 266.551544,226.986771 294.751678,255.181244 
	C295.622162,256.051544 296.986298,256.428101 298.120270,257.034851 
	C297.472198,259.134735 296.824097,261.234589 296.004333,263.666138 
z"/>
      <path fill="#696CBF" opacity="1.000000" stroke="none"
        d="
M298.426514,256.838013 
	C296.986298,256.428101 295.622162,256.051544 294.751678,255.181244 
	C266.551544,226.986771 238.398987,198.744736 210.096252,170.200409 
	C211.092758,168.647141 212.232330,167.403275 213.805542,166.334534 
	C241.783707,194.129608 269.322876,221.754959 296.900787,249.341553 
	C297.665649,250.106644 298.846375,250.456070 299.833160,250.999313 
	C299.466370,252.879929 299.099579,254.760559 298.426514,256.838013 
z"/>
      <path fill="#7A5EB7" opacity="1.000000" stroke="none"
        d="
M281.344116,283.970581 
	C279.743286,282.612640 278.515869,281.043182 277.119232,279.642426 
	C247.822449,250.259674 218.506561,220.895966 189.074341,191.210709 
	C189.572342,190.138153 190.190399,189.383438 191.200104,188.352173 
	C193.132843,189.288788 194.815506,190.360107 196.194183,191.736053 
	C224.359482,219.845612 252.485977,247.994049 280.639526,276.115417 
	C282.040466,277.514801 283.616028,278.739349 285.110291,280.045319 
	C283.953125,281.309418 282.795929,282.573517 281.344116,283.970581 
z"/>
      <path fill="#7760B8" opacity="1.000000" stroke="none"
        d="
M285.391083,279.924133 
	C283.616028,278.739349 282.040466,277.514801 280.639526,276.115417 
	C252.485977,247.994049 224.359482,219.845612 196.194183,191.736053 
	C194.815506,190.360107 193.132843,189.288788 191.238586,188.058807 
	C189.201416,188.017120 187.517380,187.992218 185.411606,187.547226 
	C182.993835,184.140121 180.997803,181.153122 179.001770,178.166122 
	C182.034958,178.121719 185.068146,178.077316 188.551788,178.099487 
	C189.897736,179.506821 190.599808,181.040573 191.716919,182.160233 
	C222.348984,212.862625 253.019653,243.526505 283.706451,274.174194 
	C284.741150,275.207581 286.009613,276.006866 287.501587,276.956177 
	C287.113312,277.932373 286.392609,278.867645 285.391083,279.924133 
z"/>
      <path fill="#8C4EAF" opacity="1.000000" stroke="none"
        d="
M166.915085,210.722717 
	C168.341354,211.472275 169.669617,212.212219 170.704926,213.245636 
	C198.119644,240.609940 225.507294,268.001373 252.877914,295.409790 
	C253.676773,296.209717 254.241425,297.243561 254.955948,298.501160 
	C253.273529,299.230438 251.550430,299.626923 249.435089,300.069336 
	C247.568497,297.783508 246.462311,295.086029 244.564148,293.175354 
	C219.341919,267.786896 194.001099,242.516235 168.723404,217.182755 
	C167.345154,215.801437 166.262619,214.125046 165.003296,212.232910 
	C165.536285,211.536484 166.108429,211.193161 166.915085,210.722717 
z"/>
      <path fill="#676FC0" opacity="1.000000" stroke="none"
        d="
M299.911438,250.631058 
	C298.846375,250.456070 297.665649,250.106644 296.900787,249.341553 
	C269.322876,221.754959 241.783707,194.129608 214.096100,166.200226 
	C214.764771,164.975616 215.576508,164.060425 216.813675,163.327591 
	C226.028793,172.376022 234.849747,181.211365 243.601456,190.114777 
	C260.159149,206.959427 276.632782,223.887161 293.266266,240.656448 
	C295.407471,242.815155 298.292297,244.236298 300.832672,245.999100 
	C300.551697,247.420334 300.270721,248.841568 299.911438,250.631058 
z"/>
      <path fill="#6570C1" opacity="1.000000" stroke="none"
        d="
M300.899292,245.585815 
	C298.292297,244.236298 295.407471,242.815155 293.266266,240.656448 
	C276.632782,223.887161 260.159149,206.959427 243.601456,190.114777 
	C234.849747,181.211365 226.028793,172.376022 217.095947,163.200592 
	C217.423035,162.315887 217.893295,161.740524 218.626129,161.059830 
	C220.380447,162.257614 221.953400,163.479416 223.351883,164.875946 
	C247.457993,188.948517 271.536407,213.048828 295.648041,237.115875 
	C297.036041,238.501328 298.656128,239.654282 300.500702,240.956543 
	C300.877441,242.389267 300.921661,243.780899 300.899292,245.585815 
z"/>
      <path fill="#6D69BD" opacity="1.000000" stroke="none"
        d="
M133.109772,91.045593 
	C131.310867,93.284630 129.511963,95.523666 127.100838,97.762085 
	C97.521645,68.868385 68.545815,39.984177 39.620689,11.049275 
	C38.483181,9.911383 37.829773,8.289556 36.950802,6.893217 
	C39.348541,5.926415 41.746277,4.959613 44.830612,4.110548 
	C73.221031,31.861788 100.920822,59.499336 128.640411,87.117004 
	C130.042801,88.514244 131.616074,89.739990 133.109772,91.045593 
z"/>
      <path fill="#8455B2" opacity="1.000000" stroke="none"
        d="
M102.050613,122.105072 
	C100.597328,123.665955 99.144051,125.226845 97.088974,126.774719 
	C95.157028,125.506729 93.794388,124.284256 92.501511,122.991989 
	C65.297157,95.800301 38.110252,68.591118 10.874262,41.431175 
	C9.516204,40.076912 7.745268,39.136681 6.166579,38.003666 
	C7.552268,35.403492 8.937957,32.803322 10.781000,30.357794 
	C12.489883,31.839287 13.708825,33.198704 14.997728,34.488194 
	C42.705997,62.209099 70.426125,89.918159 98.124886,117.648552 
	C99.521172,119.046455 100.745964,120.615646 102.050613,122.105072 
z"/>
      <path fill="#8851B0" opacity="1.000000" stroke="none"
        d="
M5.998281,38.332058 
	C7.745268,39.136681 9.516204,40.076912 10.874262,41.431175 
	C38.110252,68.591118 65.297157,95.800301 92.501511,122.991989 
	C93.794388,124.284256 95.157028,125.506729 96.795563,126.906448 
	C95.639397,128.956970 94.174843,130.862747 92.099411,132.765045 
	C90.161629,131.510284 88.802040,130.291672 87.512604,129.002869 
	C60.967266,102.470360 34.439693,75.920044 7.862605,49.419384 
	C6.508010,48.068687 4.741163,47.131435 3.166259,46.001686 
	C4.054167,43.554607 4.942075,41.107529 5.998281,38.332058 
z"/>
      <path fill="#8058B4" opacity="1.000000" stroke="none"
        d="
M102.346436,121.967667 
	C100.745964,120.615646 99.521172,119.046455 98.124886,117.648552 
	C70.426125,89.918159 42.705997,62.209099 14.997728,34.488194 
	C13.708825,33.198704 12.489883,31.839287 11.093830,30.203991 
	C12.009495,28.159243 13.069684,26.422945 14.528147,24.788685 
	C16.760790,26.570360 18.661646,28.183445 20.419588,29.939589 
	C48.116150,57.607803 75.798035,85.290718 103.464958,112.988564 
	C104.747894,114.272934 105.859161,115.728767 107.050385,117.104736 
	C105.581009,118.679909 104.111641,120.255081 102.346436,121.967667 
z"/>
      <path fill="#7463BA" opacity="1.000000" stroke="none"
        d="
M125.121048,102.035301 
	C124.736191,104.417442 124.351326,106.799576 123.545563,109.588074 
	C121.522774,108.935936 119.686111,108.112907 118.353485,106.784317 
	C88.587189,77.108177 58.873257,47.379505 29.166895,17.643312 
	C28.006519,16.481773 27.019232,15.147326 25.951292,13.893448 
	C27.401733,12.666449 28.852175,11.439449 30.882626,10.265018 
	C32.168015,11.236552 32.780445,12.248361 33.592216,13.061060 
	C62.502274,42.004158 91.416069,70.943604 120.411560,99.800957 
	C121.572609,100.956474 123.533058,101.308762 125.121048,102.035301 
z"/>
      <path fill="#7760B8" opacity="1.000000" stroke="none"
        d="
M25.623524,13.944235 
	C27.019232,15.147326 28.006519,16.481773 29.166895,17.643312 
	C58.873257,47.379505 88.587189,77.108177 118.353485,106.784317 
	C119.686111,108.112907 121.522774,108.935936 123.479507,109.997162 
	C123.879662,114.027832 123.924957,118.055794 123.568024,122.499184 
	C120.165802,120.943405 117.165810,118.972183 114.165810,117.000969 
	C114.107964,115.319778 114.050117,113.638596 113.958847,111.184097 
	C112.712517,108.869476 111.641678,107.186386 110.265770,105.807755 
	C82.156166,77.642357 54.007729,49.515739 25.885981,21.362452 
	C24.486130,19.961025 23.257782,18.388287 21.949577,16.895317 
	C23.064970,15.928552 24.180365,14.961787 25.623524,13.944235 
z"/>
      <path fill="#7066BB" opacity="1.000000" stroke="none"
        d="
M125.409973,101.871887 
	C123.533058,101.308762 121.572609,100.956474 120.411560,99.800957 
	C91.416069,70.943604 62.502274,42.004158 33.592216,13.061060 
	C32.780445,12.248361 32.168015,11.236552 31.180695,10.131632 
	C32.682537,8.963783 34.466316,7.981888 36.600449,6.946605 
	C37.829773,8.289556 38.483181,9.911383 39.620689,11.049275 
	C68.545815,39.984177 97.521645,68.868385 126.797287,97.905746 
	C126.636932,99.269516 126.167915,100.488991 125.409973,101.871887 
z"/>
      <path fill="#7C5CB6" opacity="1.000000" stroke="none"
        d="
M107.343811,116.969337 
	C105.859161,115.728767 104.747894,114.272934 103.464958,112.988564 
	C75.798035,85.290718 48.116150,57.607803 20.419588,29.939589 
	C18.661646,28.183445 16.760790,26.570360 14.765151,24.521709 
	C15.517664,23.162886 16.431448,22.173069 17.928940,21.210564 
	C19.843576,22.493717 21.206974,23.717073 22.500656,25.010180 
	C50.546764,53.043716 78.581039,81.089104 106.636948,109.112823 
	C108.038918,110.513191 109.615601,111.738640 111.110931,113.045532 
	C109.953033,114.308334 108.795128,115.571129 107.343811,116.969337 
z"/>
      <path fill="#7A5EB7" opacity="1.000000" stroke="none"
        d="
M111.383652,112.931778 
	C109.615601,111.738640 108.038918,110.513191 106.636948,109.112823 
	C78.581039,81.089104 50.546764,53.043716 22.500656,25.010180 
	C21.206974,23.717073 19.843576,22.493717 18.204306,21.092869 
	C19.051878,19.687428 20.207794,18.426989 21.656643,17.030933 
	C23.257782,18.388287 24.486130,19.961025 25.885981,21.362452 
	C54.007729,49.515739 82.156166,77.642357 110.265770,105.807755 
	C111.641678,107.186386 112.712517,108.869476 113.942863,110.764091 
	C113.192329,111.684258 112.424347,112.251137 111.383652,112.931778 
z"/>
      <path fill="#8C4EAF" opacity="1.000000" stroke="none"
        d="
M3.084610,46.391273 
	C4.741163,47.131435 6.508010,48.068687 7.862605,49.419384 
	C34.439693,75.920044 60.967266,102.470360 87.512604,129.002869 
	C88.802040,130.291672 90.161629,131.510284 91.797203,132.905731 
	C91.637840,134.270233 91.169823,135.490601 90.411644,136.872833 
	C88.395096,135.837265 86.441193,134.868195 84.976143,133.408478 
	C59.245079,107.771118 33.588722,82.058762 7.849897,56.429226 
	C6.402841,54.988308 4.383481,54.122139 2.257293,52.973038 
	C2.257052,50.898701 2.630006,48.839783 3.084610,46.391273 
z"/>
      <path fill="#696CBF" opacity="1.000000" stroke="none"
        d="
M133.392151,90.923325 
	C131.616074,89.739990 130.042801,88.514244 128.640411,87.117004 
	C100.920822,59.499336 73.221031,31.861788 45.206993,4.088241 
	C47.004131,3.310092 49.111492,2.671986 51.609596,2.099941 
	C52.660660,3.604124 52.982224,5.380314 54.030773,6.430986 
	C81.135490,33.590611 108.315895,60.674702 135.793488,87.911743 
	C135.294022,88.968094 134.484268,89.884575 133.392151,90.923325 
z"/>
      <path fill="#7C5CB6" opacity="1.000000" stroke="none"
        d="
M114.099121,117.461548 
	C117.165810,118.972183 120.165802,120.943405 123.499847,122.956314 
	C123.878822,127.358887 123.923759,131.719772 123.547585,136.535385 
	C120.139763,134.994186 117.153053,132.998230 114.166344,131.002289 
	C114.121704,126.642235 114.077065,122.282188 114.099121,117.461548 
z"/>
      <path fill="#7C5CB6" opacity="1.000000" stroke="none"
        d="
M178.540985,178.099411 
	C180.997803,181.153122 182.993835,184.140121 184.995209,187.480652 
	C180.640305,187.879074 176.280045,187.923965 171.454437,187.547760 
	C168.992767,184.139954 166.996429,181.153244 165.000092,178.166534 
	C169.360138,178.121933 173.720169,178.077316 178.540985,178.099411 
z"/>
      <path fill="#B02F9E" opacity="1.000000" stroke="none"
        d="
M82.721893,231.226120 
	C81.602135,230.031860 80.736595,229.084885 79.915642,228.186691 
	C87.142403,218.996368 87.574608,218.992935 95.125313,228.116653 
	C93.961655,229.366302 92.747192,230.643463 91.563843,231.948822 
	C90.346237,233.292007 89.159012,234.662750 87.251762,236.821304 
	C85.607155,234.764267 84.291641,233.118835 82.721893,231.226120 
z"/>
      <path fill="#B02F9E" opacity="1.000000" stroke="none"
        d="
M75.218292,207.743973 
	C82.163040,215.116623 84.073280,212.859802 73.962021,222.284744 
	C72.624199,221.007233 71.294182,219.651581 69.870636,218.402527 
	C68.520500,217.217850 67.072418,216.144775 64.940987,214.442444 
	C68.862610,211.723160 71.911232,209.609207 75.218292,207.743973 
z"/>
      <path fill="#7066BB" opacity="1.000000" stroke="none"
        d="
M146.166565,130.002090 
	C146.122116,126.305977 146.077667,122.609863 146.452759,118.462204 
	C149.526047,119.673599 152.179825,121.336533 154.833588,122.999466 
	C154.878250,126.695099 154.922913,130.390732 154.561127,134.501343 
	C151.491974,133.278229 148.829269,131.640167 146.166565,130.002090 
z"/>
      <path fill="#6D69BD" opacity="1.000000" stroke="none"
        d="
M154.900635,122.540405 
	C152.179825,121.336533 149.526047,119.673599 146.519379,118.005692 
	C146.121964,113.973816 146.077484,109.946922 146.434265,105.502899 
	C149.501633,106.724243 152.167740,108.362701 154.833862,110.001160 
	C154.878464,114.027878 154.923065,118.054611 154.900635,122.540405 
z"/>
      <path fill="#8058B4" opacity="1.000000" stroke="none"
        d="
M152.110428,156.045013 
	C149.297653,158.964401 146.484863,161.883789 143.218292,164.649170 
	C140.805893,162.648178 138.847290,160.801208 136.888672,158.954224 
	C139.706619,156.031219 142.524567,153.108215 145.788696,150.346878 
	C148.193405,152.354034 150.151917,154.199524 152.110428,156.045013 
z"/>
      <path fill="#7760B8" opacity="1.000000" stroke="none"
        d="
M146.100143,130.458649 
	C148.829269,131.640167 151.491974,133.278229 154.494064,134.957779 
	C154.877975,138.695267 154.922501,142.391281 154.547119,146.538483 
	C151.473740,145.326889 148.820251,143.664108 146.166763,142.001328 
	C146.122421,138.305954 146.078064,134.610596 146.100143,130.458649 
z"/>
      <path fill="#8455B2" opacity="1.000000" stroke="none"
        d="
M164.580383,178.100983 
	C166.996429,181.153244 168.992767,184.139954 170.994125,187.480164 
	C167.634277,187.878235 164.269409,187.922806 160.410645,187.570862 
	C158.241302,185.434769 156.565872,183.695206 154.890442,181.955627 
	C156.911270,178.120438 160.295898,177.510223 164.580383,178.100983 
z"/>
      <path fill="#676FC0" opacity="1.000000" stroke="none"
        d="
M154.900711,109.547028 
	C152.167740,108.362701 149.501633,106.724243 146.500824,105.044106 
	C146.121719,101.637222 146.077332,98.272011 146.099762,94.452576 
	C148.832458,95.636795 151.498352,97.275246 154.498886,98.955292 
	C154.878204,102.362213 154.922882,105.727547 154.900711,109.547028 
z"/>
      <path fill="#696CBF" opacity="1.000000" stroke="none"
        d="
M198.559265,147.100388 
	C200.664673,149.820343 202.327255,152.473984 203.995331,155.480515 
	C200.636230,155.877960 197.271622,155.922485 193.412354,155.570541 
	C191.261703,153.080902 189.605713,150.987747 187.949722,148.894562 
	C191.338638,148.274399 194.727554,147.654236 198.559265,147.100388 
z"/>
      <path fill="#8455B2" opacity="1.000000" stroke="none"
        d="
M114.100052,131.456390 
	C117.153053,132.998230 120.139763,134.994186 123.479965,136.995728 
	C124.373528,140.772781 124.423615,144.386932 120.227264,146.640442 
	C117.897400,144.996765 116.032593,143.500000 114.167786,142.003220 
	C114.123108,138.638977 114.078430,135.274719 114.100052,131.456390 
z"/>
      <path fill="#8851B0" opacity="1.000000" stroke="none"
        d="
M125.888657,169.953735 
	C128.025116,167.710526 130.161591,165.467316 132.913239,163.236267 
	C135.162125,165.102203 136.795822,166.955963 138.429520,168.809723 
	C136.504684,171.147125 134.579849,173.484528 132.209930,175.658569 
	C129.806122,173.648056 127.847389,171.800888 125.888657,169.953735 
z"/>
      <path fill="#7A5EB7" opacity="1.000000" stroke="none"
        d="
M146.100220,142.436722 
	C148.820251,143.664108 151.473740,145.326889 154.480133,146.994858 
	C156.108566,150.391495 156.350708,153.534714 152.420792,155.897675 
	C150.151917,154.199524 148.193405,152.354034 146.091766,150.202591 
	C145.976974,147.555130 146.005325,145.213623 146.100220,142.436722 
z"/>
      <path fill="#904BAD" opacity="1.000000" stroke="none"
        d="
M127.111115,181.045303 
	C125.622437,182.641129 124.133759,184.236938 122.205261,185.664581 
	C119.806244,183.648499 117.847054,181.800613 115.887863,179.952728 
	C117.377602,178.358002 118.867340,176.763290 120.796539,175.336563 
	C123.194366,177.351456 125.152740,179.198380 127.111115,181.045303 
z"/>
      <path fill="#A33AA4" opacity="1.000000" stroke="none"
        d="
M104.110031,204.043579 
	C102.698311,205.604553 101.286591,207.165512 99.965355,208.626419 
	C97.878067,206.441650 95.907753,204.379318 93.265892,201.614090 
	C94.241936,200.791809 95.796341,199.482285 97.793694,198.339478 
	C100.194435,200.352005 102.152229,202.197784 104.110031,204.043579 
z"/>
      <path fill="#8455B2" opacity="1.000000" stroke="none"
        d="
M138.777527,168.822342 
	C136.795822,166.955963 135.162125,165.102203 133.212448,163.098389 
	C134.034256,161.703125 135.172028,160.457932 136.599243,159.083481 
	C138.847290,160.801208 140.805893,162.648178 142.906143,164.802124 
	C141.740372,166.351059 140.432968,167.593018 138.777527,168.822342 
z"/>
      <path fill="#9D3FA7" opacity="1.000000" stroke="none"
        d="
M111.109406,197.044937 
	C109.942764,198.296021 108.776131,199.547104 107.122375,200.534134 
	C104.720436,198.498322 102.805611,196.726578 100.890793,194.954834 
	C102.048782,193.690262 103.206772,192.425705 104.800583,191.333511 
	C107.194069,193.352219 109.151741,195.198578 111.109406,197.044937 
z"/>
      <path fill="#6D69BD" opacity="1.000000" stroke="none"
        d="
M187.646057,149.039734 
	C189.605713,150.987747 191.261703,153.080902 192.958420,155.503845 
	C189.247467,155.833618 185.495804,155.833618 179.916977,155.833618 
	C183.064484,153.015335 185.203430,151.100128 187.646057,149.039734 
z"/>
      <path fill="#9348AB" opacity="1.000000" stroke="none"
        d="
M115.590004,180.088989 
	C117.847054,181.800613 119.806244,183.648499 121.907066,185.803467 
	C120.934532,187.075775 119.820358,188.041000 118.237411,188.751678 
	C116.143372,186.981171 114.518097,185.465195 112.892822,183.949219 
	C113.692596,182.707886 114.492363,181.466568 115.590004,180.088989 
z"/>
      <path fill="#9646AA" opacity="1.000000" stroke="none"
        d="
M112.562988,183.978577 
	C114.518097,185.465195 116.143372,186.981171 117.910370,188.801422 
	C117.250717,190.347260 116.449341,191.588821 115.206467,192.663147 
	C112.805901,190.648560 110.846832,188.801193 108.887756,186.953842 
	C110.002884,185.971863 111.118019,184.989899 112.562988,183.978577 
z"/>
      <path fill="#A03CA5" opacity="1.000000" stroke="none"
        d="
M100.611244,195.072266 
	C102.805611,196.726578 104.720436,198.498322 106.800964,200.551071 
	C106.210236,201.816132 105.453796,202.800186 104.403687,203.913910 
	C102.152229,202.197784 100.194435,200.352005 98.095383,198.199356 
	C98.746651,196.991562 99.539177,196.090622 100.611244,195.072266 
z"/>
      <path fill="#8851B0" opacity="1.000000" stroke="none"
        d="
M114.101349,142.448608 
	C116.032593,143.500000 117.897400,144.996765 119.903992,146.800781 
	C118.439194,148.811417 116.832626,150.514801 115.226051,152.218185 
	C114.829010,152.087189 114.431961,151.956192 114.034920,151.825180 
	C114.034920,148.848129 114.034920,145.871063 114.101349,142.448608 
z"/>
      <path fill="#8851B0" opacity="1.000000" stroke="none"
        d="
M154.583145,182.100098 
	C156.565872,183.695206 158.241302,185.434769 159.956665,187.503265 
	C156.775665,187.832214 153.554718,187.832214 148.936325,187.832214 
	C151.267258,185.392960 152.771561,183.818756 154.583145,182.100098 
z"/>
      <path fill="#8E4CAE" opacity="1.000000" stroke="none"
        d="
M127.394501,180.923233 
	C125.152740,179.198380 123.194366,177.351456 121.094650,175.197479 
	C121.764740,173.974655 122.576187,173.058899 123.811890,172.324127 
	C126.194252,174.351807 128.152344,176.198517 130.110443,178.045212 
	C129.299591,178.963867 128.488739,179.882523 127.394501,180.923233 
z"/>
      <path fill="#8C4EAF" opacity="1.000000" stroke="none"
        d="
M130.373840,177.941177 
	C128.152344,176.198517 126.194252,174.351807 124.094742,172.198135 
	C124.423119,171.315216 124.892899,170.739288 125.625671,170.058533 
	C127.847389,171.800888 129.806122,173.648056 131.906586,175.802124 
	C131.577972,176.685089 131.107605,177.261124 130.373840,177.941177 
z"/>
      <path fill="#9B41A8" opacity="1.000000" stroke="none"
        d="
M111.373772,196.941772 
	C109.151741,195.198578 107.194069,193.352219 105.095016,191.199036 
	C105.424812,190.313980 105.895981,189.735779 106.937454,189.196228 
	C109.355255,191.191788 111.202759,193.148697 113.050262,195.105621 
	C112.579552,195.683273 112.108841,196.260941 111.373772,196.941772 
z"/>
      <path fill="#9843A9" opacity="1.000000" stroke="none"
        d="
M113.319176,194.993301 
	C111.202759,193.148697 109.355255,191.191788 107.201797,189.091644 
	C107.380211,188.356506 107.864586,187.764618 108.618362,187.063278 
	C110.846832,188.801193 112.805901,190.648560 114.906586,192.802933 
	C114.561501,193.700287 114.074791,194.290634 113.319176,194.993301 
z"/>
    </g>
  ),

  //   <chakra.svg
  //   height="50px"
  //   width="50px"
  //   viewBox="0 0 100 100"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   color={fill}
  //   {...props}
  // >
  // </chakra.svg>
});

export const CreditIcon = createIcon({
  displayName: "CreditIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z"
    />
  ),
});

export const DashboardLogo = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 1000 257",
  path: (
    <g width="998" height="257" viewBox="0 0 998 257" fill="none">
      <g clip-path="url(#clip0)">
        <path
          d="M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z"
          fill="currentColor"
        />
        <path
          d="M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z"
          fill="currentColor"
        />
        <path
          d="M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z"
          fill="currentColor"
        />
        <path
          d="M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z"
          fill="currentColor"
        />
        <path
          d="M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z"
          fill="currentColor"
        />
        <path
          d="M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z"
          fill="currentColor"
        />
        <rect width="257" height="257" rx="128.5" fill="#4FD1C5" />
        <path
          d="M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="128.5"
          y1="0"
          x2="128.5"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7BCBD4" />
          <stop offset="1" stop-color="#29C6B7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="997.832" height="257" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export const DashboardLogoWhite = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 163.5 42",
  path: (
    <g fill="none">
      <path
        d="M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z"
        fill="#fff"
      />
      <path
        d="M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z"
        fill="#3BCBBE"
      />
    </g>
  ),
});

export const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M18.809 10.344h-6.153a2.11 2.11 0 01-2.11-2.11V2.083a.176.176 0 00-.175-.176H6.328A2.812 2.812 0 003.516 4.72v14.063a2.812 2.812 0 002.812 2.812h9.844a2.812 2.812 0 002.812-2.813V10.52a.176.176 0 00-.175-.176z"
      />
      <path
        fill="currentColor"
        d="M18.423 8.789l-6.32-6.32a.088.088 0 00-.15.062v5.705a.703.703 0 00.703.703h5.705a.088.088 0 00.062-.15z"
      />
    </g>
  ),
});

export const GlobeIcon = createIcon({
  displayName: "GlobeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109a9.14 9.14 0 100 18.281 9.14 9.14 0 000-18.281z"
        stroke-width=".75"
        stroke-miterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109C8.698 2.109 6.3 6.2 6.3 11.249c0 5.048 2.4 9.14 4.951 9.14 2.552 0 4.951-4.092 4.951-9.14 0-5.048-2.399-9.14-4.95-9.14z"
        stroke-width=".75"
        stroke-miterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904M17.344 17.344c-1.68-1.193-3.796-1.904-6.094-1.904-2.298 0-4.413.711-6.094 1.904"
        stroke-width=".75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109v18.28M20.39 11.249H2.11"
        stroke-width=".75"
        stroke-miterlimit="10"
      />
    </g>
  ),
});

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M11.494 4.951a.351.351 0 00-.486 0l-8.09 7.729a.352.352 0 00-.109.254v7.254a1.406 1.406 0 001.405 1.406h4.223a.703.703 0 00.704-.703v-5.976a.351.351 0 01.351-.352h3.516a.351.351 0 01.351.352v5.976a.703.703 0 00.704.703h4.22a1.407 1.407 0 001.407-1.406v-7.254a.35.35 0 00-.108-.254L11.494 4.95z"
      />
      <path
        fill="currentColor"
        d="M21.574 11.23l-3.287-3.144V3.314a.703.703 0 00-.703-.703h-2.11a.703.703 0 00-.703.703V4.72l-2.545-2.434c-.239-.24-.593-.378-.976-.378-.38 0-.734.138-.972.379L.93 11.23a.717.717 0 00-.058.983.703.703 0 001.018.046l9.119-8.713a.352.352 0 01.486 0l9.12 8.713a.703.703 0 00.992-.019c.27-.28.248-.74-.033-1.01z"
      />
    </g>
  ),
});

export const InvisionLogo = createIcon({
  displayName: "InvisionLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clip-path="url(#clip0)">
      <path
        d="M18.687 0H2.313A1.813 1.813 0 00.5 1.811v16.374C.5 19.188 1.312 20 2.313 20h16.374a1.813 1.813 0 001.813-1.813V1.812A1.813 1.813 0 0018.687 0z"
        fill="#DC395F"
      />
      <path
        d="M7.184 6.293c.665 0 1.222-.522 1.222-1.204 0-.683-.557-1.204-1.222-1.204-.665 0-1.222.521-1.222 1.204 0 .682.557 1.204 1.222 1.204zM4.65 12.739a4.134 4.134 0 00-.108.905c0 1.06.575 1.764 1.797 1.764 1.013 0 1.834-.602 2.426-1.573l-.361 1.449h2.012l1.15-4.612c.287-1.168.844-1.774 1.689-1.774.665 0 1.078.413 1.078 1.096 0 .197-.018.413-.09.646l-.593 2.12c-.09.306-.126.611-.126.899 0 1.006.593 1.742 1.833 1.742 1.06 0 1.904-.682 2.371-2.317l-.79-.305c-.395 1.095-.737 1.293-1.006 1.293-.27 0-.414-.18-.414-.538 0-.162.037-.342.09-.558l.575-2.065c.144-.485.198-.915.198-1.31 0-1.546-.934-2.352-2.066-2.352-1.06 0-2.138.956-2.677 1.962l.395-1.806H8.962L8.53 8.996h1.438l-.885 3.544c-.695 1.545-1.972 1.57-2.132 1.534-.264-.06-.432-.159-.432-.5 0-.198.036-.482.126-.823l1.348-5.346H4.579l-.431 1.591h1.419L4.65 12.74"
        fill="#fff"
      />
    </g>
  ),
});

export const JiraLogo = createIcon({
  displayName: "JiraLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clip-path="url(#clip0)">
      <path
        d="M20.26 10.42l-8.863-8.93-.86-.865-6.67 6.722L.814 10.42a.827.827 0 000 1.162l6.096 6.14 3.627 3.654 6.67-6.722.105-.104 2.947-2.964a.824.824 0 000-1.166zm-9.722 3.649L7.493 11l3.045-3.068L13.582 11l-3.044 3.068z"
        fill="#2684FF"
      />
      <path
        d="M10.537 7.932a5.184 5.184 0 01-1.502-3.637A5.185 5.185 0 0110.515.65L3.852 7.36l3.626 3.654 3.06-3.082z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.59 10.992l-3.053 3.076a5.186 5.186 0 011.502 3.653c0 1.37-.54 2.683-1.502 3.652l6.682-6.728-3.63-3.653z"
        fill="url(#paint1_linear)"
      />
    </g>
  ),
});

export const MastercardIcon = createIcon({
  displayName: "MastercardIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="24"
      height="20"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.63158" cy="7.49999" r="6.63158" fill="#EB001B" />
      <circle cx="14.3686" cy="7.49999" r="6.63158" fill="#F79E1B" />
    </svg>
  ),
});

export const PayPalIcon = createIcon({
  displayName: "PayPalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M16.179 2.532C15.142 1.357 13.265.854 10.863.854H3.895a.997.997 0 00-.986.834L.007 19.945a.595.595 0 00.591.686H4.9l1.08-6.8-.033.215a.993.993 0 01.983-.834h2.044c4.017 0 7.16-1.619 8.08-6.3.027-.14.07-.406.07-.406.262-1.732-.001-2.907-.945-3.974z"
      />
      <path
        fill="currentColor"
        d="M18.213 7.393c-.998 4.61-4.184 7.048-9.24 7.048H7.142L5.773 23.1h2.973a.87.87 0 00.862-.731l.035-.184.684-4.297.044-.237a.87.87 0 01.86-.731h.544c3.514 0 6.264-1.416 7.068-5.51.322-1.644.166-3.021-.63-4.017z"
      />
    </g>
  ),
});

export const PersonIcon = createIcon({
  displayName: "PersonIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M14.618 3.338c-.855-.924-2.05-1.432-3.368-1.432-1.325 0-2.524.505-3.375 1.423-.86.928-1.28 2.188-1.181 3.55.195 2.686 2.239 4.87 4.556 4.87s4.358-2.184 4.556-4.87c.1-1.349-.322-2.607-1.188-3.541zM18.984 21.592H3.515a1.363 1.363 0 01-1.063-.489 1.552 1.552 0 01-.316-1.279c.371-2.058 1.529-3.786 3.348-5 1.616-1.076 3.664-1.67 5.766-1.67s4.15.594 5.765 1.67c1.82 1.214 2.977 2.942 3.348 5 .085.471-.03.937-.315 1.279a1.362 1.362 0 01-1.064.49z"
    />
  ),
});

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path d="M0 0h24v24H0V0z" fill="transparent" />
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
      />
    </g>
  ),
});

export const RocketIcon = createIcon({
  displayName: "RocketIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z"
      />
      <path
        fill="currentColor"
        d="M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z"
      />
    </g>
  ),
});

export const SettingsIcon = createIcon({
  displayName: "SettingsIcon",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
      />
    </g>
  ),
});

export const SlackLogo = createIcon({
  displayName: "SlackLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clip-path="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd">
      <path
        d="M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z"
        fill="#36C5F0"
      />
      <path
        d="M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z"
        fill="#2EB67D"
      />
      <path
        d="M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z"
        fill="#ECB22E"
      />
      <path
        d="M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z"
        fill="#E01E5A"
      />
    </g>
  ),
});

export const SpotifyLogo = createIcon({
  displayName: "SpotifyLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clip-path="url(#clip0)">
      <path
        d="M10.5 0C4.977 0 .5 4.477.5 10s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm4.586 14.422a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.216c3.808-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857zm1.223-2.722a.78.78 0 01-1.072.257c-2.687-1.652-6.786-2.13-9.965-1.166a.78.78 0 01-.973-.519.781.781 0 01.52-.972c3.632-1.102 8.147-.569 11.233 1.329a.78.78 0 01.258 1.072zm.106-2.835C13.19 6.95 7.875 6.775 4.797 7.708a.935.935 0 11-.543-1.79c3.533-1.072 9.404-.865 13.115 1.338a.935.935 0 01-.954 1.609z"
        fill="#2EBD59"
      />
    </g>
  ),
});

export const SupportIcon = createIcon({
  // Doesn't display the full icon without w and h being specified
  displayName: "BuildIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M20.885 5.547a.703.703 0 00-1.122-.176l-2.7 2.702a.708.708 0 01-.995 0l-1.167-1.169a.702.702 0 010-.994l2.689-2.69a.704.704 0 00-.21-1.138c-2.031-.908-4.566-.435-6.164 1.152-1.358 1.348-1.763 3.455-1.11 5.78a.698.698 0 01-.197.703L2.593 16.4a2.82 2.82 0 103.981 3.983l6.754-7.332a.699.699 0 01.693-.2 7.885 7.885 0 002.03.279c1.469 0 2.757-.475 3.686-1.39 1.72-1.695 1.983-4.57 1.148-6.192zM4.623 19.901a1.407 1.407 0 11-.305-2.797 1.407 1.407 0 01.305 2.797z"
    />
  ),
});

export const StatsIcon = createIcon({
  displayName: "StatsIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M4.57 22.297H3.164a1.055 1.055 0 01-1.055-1.054v-6.328a1.055 1.055 0 011.055-1.055H4.57a1.055 1.055 0 011.055 1.055v6.328a1.055 1.055 0 01-1.055 1.054zM14.414 22.296h-1.406a1.055 1.055 0 01-1.055-1.055V10.695a1.055 1.055 0 011.055-1.055h1.406a1.055 1.055 0 011.055 1.055V21.24a1.055 1.055 0 01-1.055 1.055zM19.336 22.297H17.93a1.055 1.055 0 01-1.055-1.055V5.773A1.055 1.055 0 0117.93 4.72h1.406a1.055 1.055 0 011.055 1.054v15.47a1.055 1.055 0 01-1.055 1.054zM9.492 22.297H8.086a1.055 1.055 0 01-1.055-1.055V2.257a1.055 1.055 0 011.055-1.054h1.406a1.055 1.055 0 011.055 1.054v18.985a1.055 1.055 0 01-1.055 1.055z"
    />
  ),
});

export const WalletIcon = createIcon({
  displayName: "WalletIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z"
      />
      <path
        fill="currentColor"
        d="M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z"
      />
    </g>
  ),
});

export const VisaIcon = createIcon({
  displayName: "VisaIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="26"
      height="20"
      viewBox="0 0 30 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6708 2.96349C12.6568 4.15055 13.653 4.81298 14.4034 5.20684C15.1744 5.61099 15.4333 5.87013 15.4304 6.23147C15.4245 6.78459 14.8153 7.02862 14.2452 7.03812C13.2505 7.05475 12.6723 6.74889 12.2125 6.51753L11.8542 8.32341C12.3155 8.55247 13.1696 8.75217 14.0554 8.76087C16.1345 8.76087 17.4947 7.65543 17.5021 5.94145C17.5102 3.76625 14.7087 3.64579 14.7278 2.67348C14.7344 2.37871 14.9956 2.06408 15.568 1.98406C15.8512 1.94368 16.6332 1.91274 17.5198 2.35252L17.8677 0.605286C17.391 0.418253 16.7782 0.239136 16.0153 0.239136C14.0583 0.239136 12.6819 1.35962 12.6708 2.96349ZM21.2115 0.389687C20.8319 0.389687 20.5118 0.628245 20.3691 0.99433L17.3991 8.63249H19.4767L19.8901 7.40183H22.429L22.6689 8.63249H24.5L22.9021 0.389687H21.2115ZM21.5021 2.61641L22.1016 5.7116H20.4596L21.5021 2.61641ZM10.1518 0.389687L8.51418 8.63249H10.4939L12.1308 0.389687H10.1518ZM7.22303 0.389687L5.16233 6.00003L4.32878 1.22966C4.23097 0.697187 3.84472 0.389687 3.41579 0.389687H0.0471011L0 0.629037C0.691574 0.790671 1.47729 1.0514 1.95326 1.33033C2.24457 1.50067 2.32775 1.64964 2.42336 2.05458L4.00214 8.63249H6.0945L9.3021 0.389687H7.22303Z"
        fill="#4318FF"
      />
    </svg>
  ),
});
