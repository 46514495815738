import React, { useEffect, useState } from "react";
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Header from "views/Pages/Profile/Overview/components/Header";
import { getJuvolySessionId } from "api/lawdiskService";
import { addConversation } from "api/lawdiskService";
import RecordingButton from "components/Recording/RecordingButton";
import { getUserName } from "extensions/userInfoExtension";

function Opnemen() {
  // Chakra color mode
  const clientId = "lawdisk";
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const [conversation, setConversation] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [buffer, setBuffer] = useState("");
  const [webSocket, setWebSocket] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const startRecording = async () => {
    try {
      const sessionId = await getJuvolySessionId();
      if (!sessionId) {
        throw new Error("Failed to get session ID.");
      }

      const webSocket = new WebSocket(
        `wss://ai.juvoly.nl/ws/speech/${clientId}/${sessionId}`
      ); // <client id>/<session>
      setWebSocket(webSocket);

      webSocket.onopen = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          setMediaStream(stream);
          const mr = new MediaRecorder(stream);
          setMediaRecorder(mr);

          mr.ondataavailable = async ({ data }) => {
            if (webSocket?.readyState === webSocket.OPEN) {
              webSocket.send(data);
            }
          };
          mr.start(250);
        });
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result.type === "transcript") {
          result.transcript.completed.forEach((completed) => {
            setConversation((oldConversation) =>
              oldConversation.trim().concat(` ${completed.sentence}`)
            );
          });
          setBuffer(
            result.transcript.buffer.length > 0
              ? result.transcript.buffer.sentence
              : ""
          );
        }
      };

      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    if (webSocket) {
      webSocket.close();
    }

    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }

    if (buffer !== "") {
      setConversation((oldConversation) => {
        oldConversation.concat(buffer);
      });
    }

    var result = await addConversation(conversation);

    setIsRecording(false);
    setWebSocket(null); // Clear the WebSocket reference
    setConversation("");
    setBuffer("");
  };

  return (
    <Flex direction="column" minHeight="100vh">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarName={getUserName()}
        name={getUserName()}
      />
      <Card flex="1">
        <CardHeader mb="22px">
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            LawDisk™ | Notuleren - online variant
          </Text>
        </CardHeader>
        <CardBody>
          <Stack>
            <RecordingButton />
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Opnemen;
