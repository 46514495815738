import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  VStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useToast,
  RadioGroup,
  Stack,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { getJuvolySessionId } from "api/lawdiskService";
import { refreshAccessToken } from "services/authService";
import { FaMicrophone } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import { addConversation } from "api/lawdiskService";
import { SettingsIcon } from "@chakra-ui/icons";
import WaveBox from "components/Recording/WaveBox.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function RecordingButton({ onStopRecording }) {
  const clientId = "lawdisk";
  const history = useHistory();

  const [conversation, setConversation] = useState("");
  const [waitingForSetup, setWaitingForSetup] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [buffer, setBuffer] = useState("");
  const [webSocket, setWebSocket] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const originalHistoryPush = useRef(history.push); // Store the original history.push function
  const toast = useToast();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRecording) {
        event.preventDefault();
        event.returnValue = ""; // This will trigger a confirmation dialog in most browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isRecording]);

  useEffect(() => {
    // Get the list of audio input devices
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const audioDevices = deviceInfos.filter(
        (device) => device.kind === "audioinput"
      );
      setDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      } else {
        setSelectedDeviceId(null);
      }
    });

    return () => {};
  }, []);

  // Override the history push to prevent navigation during recording
  useEffect(() => {
    if (isRecording || waitingForSetup || isStopping) {
      // Override the history.push function to show the modal instead of navigating
      history.push = (path, state) => {
        setIsModalOpen(true);
      };
    } else {
      // Restore original history.push function once recording is stopped
      history.push = originalHistoryPush.current;
    }
  }, [isRecording, waitingForSetup, isStopping, history]);

  const handleClick = async () => {
    if (isDisabled) return;
    setIsDisabled(true);

    try {
      if (isRecording) {
        await stopRecording();
      } else {
        await startRecording();
      }
    } finally {
      setIsDisabled(false);
    }
  };

  const startRecording = async () => {
    setWaitingForSetup(true);
    try {
      const sessionId = await getJuvolySessionId();
      if (!sessionId) {
        throw new Error("Failed to get session ID.");
      }

      const webSocket = new WebSocket(
        `wss://ai.juvoly.nl/ws/speech/${clientId}/${sessionId}`
      );
      setWebSocket(webSocket);

      if (selectedDeviceId === null || devices.length === 0) {
        // raise custom error type EmptyDevices
        throw new EmptyDevicesError("No audio input devices found.");
      }

      webSocket.onopen = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: { deviceId: selectedDeviceId } })
          .then((stream) => {
            setMediaStream(stream);
            const mr = new MediaRecorder(stream);
            setMediaRecorder(mr);

            mr.ondataavailable = async ({ data }) => {
              if (webSocket?.readyState === WebSocket.OPEN) {
                setIsRecording(true);
                setWaitingForSetup(false);
                webSocket.send(data);
              }
            };
            mr.start(250);
          })
          .catch((error) => {
            if (error.name === "NotAllowedError") {
              toast({
                title: "Error",
                description:
                  "Geef a.u.b. toegang tot de microfoon. Ververs de pagina om het opnieuw te proberen.",
                status: "error",
                duration: 6000,
                isClosable: true,
              });
              console.error(
                "Permission denied: Unable to access the microphone."
              );
            } else {
              console.error("Error accessing media devices:", error);
            }
            setIsRecording(false);
            setWaitingForSetup(false);
          });
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result.type === "transcript") {
          result.transcript.completed.forEach((completed) => {
            setConversation((oldConversation) =>
              oldConversation.trim().concat(` ${completed.sentence}`)
            );
          });
          setBuffer(
            result.transcript.buffer.length > 0
              ? result.transcript.buffer.sentence
              : ""
          );
        }
      };
    } catch (error) {
      if (error.name === "EmptyDevicesError") {
        toast({
          title: "Error",
          description:
            "Geen microfoon gevonden op uw apparaat. Sluit alstublieft een microfoon aan en probeer het opnieuw.",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
        console.error("No audio input devices found.");
      }

      setWaitingForSetup(false);
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = async () => {
    if (isStopping) return;
    setIsStopping(true);
    if (webSocket) {
      webSocket.close();
    }

    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }

    if (buffer !== "") {
      setConversation((oldConversation) => {
        return oldConversation.concat(buffer);
      });
    }

    try {
      await ensureValidToken();
      var result = await addConversation(conversation);
      if (result.id !== undefined) {
        showToastConversationAdded();
      }

      if (onStopRecording) {
        onStopRecording();
      }
    } catch (error) {
      console.error("Error saving conversation:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving the conversation.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
    setIsStopping(false);
    setIsRecording(false);
    setWebSocket(null);
    setConversation("");
    setBuffer("");
  };

  const ensureValidToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken =
      localStorage.getItem("refreshToken") ||
      sessionStorage.getItem("refreshToken");
    const isAccessTokenExpired = checkTokenExpiry(accessToken);

    if (isAccessTokenExpired) {
      try {
        await refreshAccessToken(refreshToken);
      } catch (error) {
        console.error("Unable to refresh token:", error);
        throw new Error("Session has expired. Please log in again.");
      }
    }
  };

  const checkTokenExpiry = (token) => {
    if (!token) return true;

    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiryTime = payload.exp * 1000;

    return Date.now() > expiryTime;
  };

  function showToastConversationAdded() {
    toast({
      title: "Conversatie toegevoegd",
      description: "U kunt de transcriptie nu terug zien in uw overzicht.",
      status: "info",
      duration: 6000,
      isClosable: true,
    });
  }

  function changeDeviceId(event) {
    setSelectedDeviceId(event.target.value);
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transcriberen bezig</ModalHeader>
          <ModalBody>
            <Text>
              U bent momenteel aan het transcriberen. Stop eerst de opname
              voordat u verder gaat om uw voortgang niet te verliezen.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ChakraButton
              colorScheme="red"
              onClick={() => setIsModalOpen(false)}
            >
              Sluiten
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex>
        <HStack spacing="20px" directions={["column", "row"]}>
          <HStack spacing="20px" my="20px">
            <HStack>
              <Box>
                <ButtonGroup size="sm" isAttached>
                  <Button
                    spinner={<PulseLoader size={10} color="white" />}
                    leftIcon={<FaMicrophone />}
                    onClick={handleClick}
                    colorScheme="red"
                    isLoading={waitingForSetup || isDisabled}
                    isDisabled={isDisabled}
                    w="100%"
                  >
                    <Text fontSize="sm">
                      {isRecording
                        ? "STOP TRANSCRIBEREN"
                        : "START TRANSCRIBEREN"}
                    </Text>
                  </Button>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        colorScheme="red"
                        variant="outline"
                        aria-label="Microfoon selecteren"
                        icon={<SettingsIcon />}
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent borderColor="red">
                        <PopoverArrow />
                        <PopoverHeader>Selecteer uw microfoon:</PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody>
                          <RadioGroup>
                            <Stack>
                              <Select
                                onChange={changeDeviceId}
                                value={selectedDeviceId}
                                isDisabled={isRecording}
                                title={
                                  isRecording
                                    ? "Tijdens opname kunt u niet veranderen van microfoon."
                                    : ""
                                }
                              >
                                {devices.map((device) => (
                                  <option
                                    key={device.deviceId}
                                    value={device.deviceId}
                                  >
                                    {device.label ||
                                      `Microphone ${device.deviceId}`}
                                  </option>
                                ))}
                              </Select>
                            </Stack>
                          </RadioGroup>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </ButtonGroup>
              </Box>
              {isRecording && <WaveBox deviceId={selectedDeviceId} />}
            </HStack>
          </HStack>
        </HStack>
      </Flex>
    </>
  );
}

// Define a custom error class
class EmptyDevicesError extends Error {
  constructor(message) {
    super(message);
    this.name = "EmptyDevicesError";
  }
}

export default RecordingButton;
