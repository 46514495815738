import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import { Box, Button, Select, Text, VStack } from "@chakra-ui/react";

const WaveBox = ({ deviceId }) => {
  const [wavesurfer, setWavesurfer] = useState(null);
  const [record, setRecord] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [progress, setProgress] = useState("00:00");
  const micSelectRef = useRef(null);
  const waveRef = useRef(null);
  const scrollingWaveform = false;

  const startRecording = async () => {
    await record.startRecording({ deviceId });
    setIsRecording(true);
  };

  useEffect(() => {
    // Initialize WaveSurfer and Record plugin
    if (wavesurfer) {
      wavesurfer.destroy();
    }
    const ws = WaveSurfer.create({
      container: waveRef.current,
      waveColor: "rgb(96, 165, 250)", // This is the 'blue.300' color in RGB format ("lawdiskBlue")
      progressColor: "rgb(0, 0, 0, 0)",
    });

    const rec = ws.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform,
        renderRecordedAudio: false,
      })
    );

    rec.on("record-progress", (time) => {
      updateProgress(time);
    });

    setWavesurfer(ws);
    setRecord(rec);

    return () => {
      if (ws) ws.destroy();
    };
  }, [scrollingWaveform]);

  const updateProgress = (time) => {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    setProgress(formattedTime);
  };

  const handleMicSelect = async () => {
    const devices = await RecordPlugin.getAvailableAudioDevices();
    micSelectRef.current.innerHTML = ""; // Clear existing options
    devices.forEach((device) => {
      const option = document.createElement("option");
      option.value = device.deviceId;
      option.text = device.label || device.deviceId;
      micSelectRef.current.appendChild(option);
    });
  };

  useEffect(() => {
    handleMicSelect();
  }, []);

  const handleRecordButtonClick = async () => {
    if (isRecording) {
      record.stopRecording();
      setIsRecording(false);
    } else {
      startRecording();
    }
  };

  const startRecordingIfNotActive = async () => {
    if (!isRecording) {
      startRecording();
    }
  };

  useEffect(() => {
    if (wavesurfer && record) {
      startRecordingIfNotActive();
    }
  }, [wavesurfer, record]);
  // TODO: Add re-rendering of wavesurfer after recording (use Button and Select components for debugging by removing style display: 'none')
  return (
    <Box width="10vw">
      <Button onClick={handleRecordButtonClick} style={{ display: "none" }}>
        {isRecording ? "Stop" : "Record"}
      </Button>
      <Select
        width="100%"
        ref={micSelectRef}
        placeholder="Select mic"
        onChange={startRecordingIfNotActive}
        style={{ display: "none" }}
        icon={<></>}
      />
      <Box ref={waveRef} width="100%" height="100%" borderRadius="md" />
    </Box>
  );
};

export default WaveBox;
