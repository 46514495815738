import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  AuthFlowType,
  RespondToAuthChallengeCommand,
  ChallengeNameType,
  AssociateSoftwareTokenCommand,
  VerifySoftwareTokenCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import config from "../config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const signIn = async (username, password) => {
  const params = {
    AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    console.log("Bezig met inloggen...");
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Succesvol ingelogd:", response);
    return response;
  } catch (error) {
    console.error("Fout bij inloggen:", error);
    throw error;
  }
};

export const signOut = () => {
  console.log("Gebruiker uitloggen...");
  localStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("idToken");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  console.log("Succesvol uitgelogd.");
};

export const refreshAccessToken = async (refreshToken, rememberMe) => {
  const params = {
    AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);

    if (response.AuthenticationResult) {
      // Sla de nieuwe tokens op in localStorage
      localStorage.setItem(
        "idToken",
        response.AuthenticationResult.IdToken || ""
      );
      localStorage.setItem(
        "accessToken",
        response.AuthenticationResult.AccessToken || ""
      );

      // Sla refreshToken alleen op als deze aanwezig is
      if (response.AuthenticationResult.RefreshToken) {
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem(
          "refreshToken",
          response.AuthenticationResult.RefreshToken
        );
      }
    } else {
      throw new Error("Geen AuthenticationResult ontvangen van Cognito.");
    }

    return response.AuthenticationResult;
  } catch (error) {
    console.error("Error refreshing access token: ", error);
    throw error;
  }
};

export const ensureValidToken = async () => {
  console.log("Controleer of het toegangstoken geldig is...");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  const isAccessTokenExpired = checkTokenExpiry(accessToken);

  if (isAccessTokenExpired) {
    try {
      console.log("Toegangstoken is verlopen, probeer te verversen...");
      await refreshAccessToken();
    } catch (error) {
      console.error("Kan token niet verversen:", error);
      signOut();
      window.location.href = "/auth/sign-in";
    }
  } else {
    console.log("Toegangstoken is nog geldig.");
  }
};

const checkTokenExpiry = (token) => {
  if (!token) return true;

  const payload = JSON.parse(atob(token.split(".")[1]));
  const expiryTime = payload.exp * 1000;

  return Date.now() > expiryTime;
};

export const RespondToMfaChallenge = async (
  username,
  mfaCode,
  session,
  rememberMe
) => {
  const command = new RespondToAuthChallengeCommand({
    ChallengeName: ChallengeNameType.SOFTWARE_TOKEN_MFA,
    ChallengeResponses: {
      SOFTWARE_TOKEN_MFA_CODE: mfaCode,
      USERNAME: username,
    },
    ClientId: config.clientId,
    Session: session,
  });

  try {
    console.log("Bezig met verwerken van MFA-uitdaging...");
    const response = await cognitoClient.send(command);
    const authenticationResult = response.AuthenticationResult;

    // Sla idToken en accessToken altijd op in localStorage
    localStorage.setItem("idToken", authenticationResult.IdToken || "");
    localStorage.setItem("accessToken", authenticationResult.AccessToken || "");

    // Note: The refresh token is typically not returned here because it's already valid.
    // If a new refresh token is issued, handle it accordingly.
    if (response.AuthenticationResult.RefreshToken) {
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem("refreshToken", authenticationResult.RefreshToken || "");
    }
    console.log("MFA-uitdaging succesvol verwerkt.");
    return authenticationResult;
  } catch (error) {
    console.error("Fout bij het verwerken van de MFA-uitdaging:", error);
    throw error;
  }
};

export const RespondToNewPasswordChallenge = async (
  username,
  password,
  session
) => {
  const command = new RespondToAuthChallengeCommand({
    ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
    ClientId: config.clientId,
    Session: session,
  });

  try {
    console.log("Bezig met verwerken van nieuw wachtwoord uitdaging...");
    const response = await cognitoClient.send(command);
    console.log("Nieuw wachtwoord succesvol ingesteld.");
    return response;
  } catch (error) {
    console.error(
      "Fout bij het verwerken van de nieuw wachtwoord uitdaging:",
      error
    );
    throw error;
  }
};

export const RespondToMfaSetupChallenge = async (session, username) => {
  const command = new AssociateSoftwareTokenCommand({
    Session: session,
  });

  try {
    console.log("Bezig met instellen van MFA...");
    const response = await cognitoClient.send(command);
    const qrCodeUrl = `otpauth://totp/${username}?secret=${response.SecretCode}&issuer=${config.appName}`;
    console.log("MFA succesvol ingesteld.");
    return [response.Session, qrCodeUrl, response.SecretCode];
  } catch (error) {
    console.error("Fout bij het instellen van MFA:", error);
    throw error;
  }
};

export const VerifyMfaCode = async (mfaCode, session, username, rememberMe) => {
  const verifyCommand = new VerifySoftwareTokenCommand({
    UserCode: mfaCode,
    Session: session,
  });

  try {
    console.log("Bezig met verifiëren van MFA-code...");
    const response = await cognitoClient.send(verifyCommand);

    if (response.Status === "SUCCESS") {
      console.log("MFA-code succesvol geverifieerd.");
      const challengeCommand = new RespondToAuthChallengeCommand({
        ChallengeName: ChallengeNameType.MFA_SETUP,
        ClientId: config.clientId,
        Session: response.Session,
        ChallengeResponses: {
          USERNAME: username,
        },
      });

      const challengeResponse = await cognitoClient.send(challengeCommand);
      const authenticationResult = challengeResponse.AuthenticationResult;

      // Sla idToken en accessToken altijd op in localStorage
      localStorage.setItem("idToken", authenticationResult.IdToken || "");
      localStorage.setItem(
        "accessToken",
        authenticationResult.AccessToken || ""
      );

      // Note: The refresh token is typically not returned here because it's already valid.
      // If a new refresh token is issued, handle it accordingly.
      if (authenticationResult.RefreshToken) {
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem(
          "refreshToken",
          authenticationResult.RefreshToken || ""
        );
      }

      return authenticationResult;
    } else {
      throw new Error("Er is iets misgegaan bij het instellen van uw MFA.");
    }
  } catch (error) {
    console.error("Fout bij het verifiëren van de MFA-code:", error);
    throw error;
  }
};
