import { jwtDecode } from "jwt-decode";

export const getUserName = () => {
    const idToken = localStorage.getItem('idToken');
    const decodedToken = idToken ? jwtDecode(idToken) : null;

    if (decodedToken === null) {
        return null;
    }

    return decodedToken.email;
};

export const getUserId = () => {
    const idToken = localStorage.getItem('idToken');
    const decodedToken = idToken ? jwtDecode(idToken) : null;

    if (decodedToken === null) {
        return null;
    }
    
    return decodedToken.sub;
}

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}
