import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  Text,
  Progress,
  Image,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
  Portal,
  Fade,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { getJuvolySessionId } from "api/lawdiskService";
import { FaCloudUploadAlt } from "react-icons/fa";
import PulseLoader from "react-spinners/PulseLoader";
import { useDropzone } from "react-dropzone";
import uploadImage from "../../assets/img/cloud-computing.png";

const FileUploadCard = ({
  handleClick,
  isRecording,
  waitingForSetup,
  isDisabled,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [conversation, setConversation] = useState("");
  const [currentSentence, setCurrentSentence] = useState(""); // For live transcription
  const [isTranscribing, setIsTranscribing] = useState(false); // Transcription progress
  const [webSocket, setWebSocket] = useState(null);

  const transcriptRef = useRef(null); // For auto-scrolling
  const audioDurationRef = useRef(0); // Ref for immediate access

  const textColor = useColorModeValue("gray.700", "white");
  const bgTranscribeButton = "var(--chakra-colors-red-500)";
  const toast = useToast();
  const clientId = "lawdisk";

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        setUploadProgress(0);
        setUploadComplete(false);
        setErrorMessage("");
        handleUpload(acceptedFiles[0]);
      }
    },
    multiple: false,
    accept: "audio/*,video/*",
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      setSelectedFile(null);
      setErrorMessage("Alleen audio- en videobestanden zijn toegestaan.");
    }
  }, [fileRejections]);

  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [conversation, currentSentence]);

  const handleUpload = async (file) => {
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);
    setUploadComplete(false);
    setErrorMessage("");
    setConversation("");
    setCurrentSentence("");
    setIsTranscribing(false);
    audioDurationRef.current = 0;

    try {
      // Start WebSocket session
      const ws = await startWebSocketSession();

      // Process and stream the audio
      await processAndStreamAudio(file, ws);
    } catch (error) {
      console.error("Error during file upload:", error);
      setErrorMessage("Er is een fout opgetreden tijdens het uploaden.");
      setIsUploading(false);
    }
  };

  const processAndStreamAudio = async (file, ws) => {
    try {
      // Create AudioContext
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      // Read and decode the audio file
      const arrayBuffer = await file.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Get the duration in seconds
      audioDurationRef.current = audioBuffer.duration;

      // Resample to 16kHz mono using OfflineAudioContext
      const offlineContext = new OfflineAudioContext(
        1,
        audioBuffer.duration * 16000,
        16000
      );
      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(offlineContext.destination);
      source.start(0);

      const renderedBuffer = await offlineContext.startRendering();

      // Set up MediaRecorder
      const destination = audioContext.createMediaStreamDestination();
      const sourceNode = audioContext.createBufferSource();
      sourceNode.buffer = renderedBuffer;
      sourceNode.connect(destination);
      sourceNode.start();

      const options = {
        mimeType: "audio/webm;codecs=opus",
      };
      const mediaRecorder = new MediaRecorder(destination.stream, options);

      // Send audio data over WebSocket
      mediaRecorder.addEventListener("dataavailable", (event) => {
        if (event.data && event.data.size > 0) {
          if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(event.data);
          }
        }
      });

      mediaRecorder.onstop = () => {
        console.log("All audio data sent over WebSocket.");
        // if (ws) {
        //   ws.close();
        // }
        // setIsUploading(false);
        // setUploadComplete(true);
      };

      mediaRecorder.onerror = (error) => {
        console.error("MediaRecorder error:", error);
        setErrorMessage(
          "Er is een fout opgetreden tijdens het opnemen van audio."
        );
        setIsUploading(false);
      };

      mediaRecorder.start(100); // Send data every 100ms

      sourceNode.onended = () => {
        if (mediaRecorder.state !== "inactive") {
          mediaRecorder.stop();
        }
      };
    } catch (error) {
      console.error("Error processing audio file:", error);
      setErrorMessage(
        "Er is een fout opgetreden bij het verwerken van het bestand."
      );
      setIsUploading(false);
    }
  };

  const startWebSocketSession = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const sessionId = await getJuvolySessionId();
        const webSocketUrl = `wss://ai.juvoly.nl/ws/speech/${clientId}/${sessionId}`;
        const ws = new WebSocket(webSocketUrl);
        setWebSocket(ws);

        ws.onopen = () => {
          console.log("WebSocket connection opened.");
          resolve(ws); // Resolve the promise with the WebSocket instance
        };

        ws.onmessage = (event) => {
          const result = JSON.parse(event.data);

          if (result.type === "ready") {
            console.log("WebSocket is ready to receive audio.");
          }

          if (result.type === "transcript") {
            setIsTranscribing(true); // Start transcription progress bar

            const { completed, buffer } = result.transcript;
            console.log(result.transcript);

            let bufferEnd = 0;
            let completedEnd = 0;

            // Handle buffered sentences (intermediate results)
            if (buffer && buffer.length > 0) {
              let bufferedSentence = "";
              bufferEnd = buffer[0].end;
              buffer.forEach((sentenceObj) => {
                const sentence = sentenceObj.sentence;
                console.log(sentence);
                bufferedSentence += ` ${sentence}`;
              });
              console.log("Buffered: ", bufferedSentence);
              setCurrentSentence(bufferedSentence);
            } else {
              setCurrentSentence("");
            }

            // Handle completed sentences
            if (completed && completed.length > 0) {
              completedEnd = completed[0].end;
              completed.forEach((sentenceObj) => {
                const sentence = sentenceObj.sentence;
                console.log(sentence);
                setConversation((prev) => prev + " " + sentence);
              });
            }
            console.log(bufferEnd);
            console.log(completedEnd);
            console.log(audioDurationRef.current);
            console.log(
              Math.max(bufferEnd, completedEnd) / audioDurationRef.current
            );
            setUploadProgress((prev) =>
              Math.round(
                Math.min(
                  (Math.max(bufferEnd, completedEnd) /
                    audioDurationRef.current) *
                    100
                ),
                100
              )
            );
          }
        };

        ws.onerror = (err) => {
          console.error("WebSocket error:", err.message);
          setErrorMessage(
            "Er is een fout opgetreden bij de WebSocket verbinding."
          );
          reject(err);
        };

        ws.onclose = () => {
          console.log("WebSocket connection closed.");
          setIsUploading(false);
          setUploadComplete(true);
          setIsTranscribing(false); // Transcription finished
          setCurrentSentence(""); // Clear buffer
        };
      } catch (error) {
        console.error("Error starting WebSocket session:", error);
        setErrorMessage(
          "Er is een fout opgetreden bij het starten van de WebSocket sessie."
        );
        setIsUploading(false);
        reject(error);
      }
    });
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setUploadProgress(0);
    setIsUploading(false);
    setUploadComplete(false);
    setErrorMessage("");
    setConversation("");
    setCurrentSentence("");
    setIsTranscribing(false);
    if (webSocket) {
      webSocket.close();
      setWebSocket(null);
    }
  };

  return (
    <Flex>
      <HStack spacing="20px" direction={["column", "row"]}>
        <HStack spacing="20px" my="20px">
          <HStack>
            <Box>
              <ButtonGroup size="sm" isAttached>
                <Popover placement="bottom-start" isLazy>
                  {/* Hide the upload button after upload is complete */}
                  {!uploadComplete && (
                    <PopoverTrigger>
                      <Button
                        spinner={<PulseLoader size={10} color="white" />}
                        leftIcon={<FaCloudUploadAlt />}
                        colorScheme="green"
                        isLoading={waitingForSetup || isDisabled}
                        w="100%"
                        pl={"27.54px"}
                        pr={"27.54px"}
                        onClick={() => {}}
                      >
                        <Text fontSize="sm">UPLOAD EIGEN OPNAME</Text>
                      </Button>
                    </PopoverTrigger>
                  )}
                  <Portal>
                    <PopoverContent borderColor="green.500">
                      <PopoverHeader>Selecteer uw bestand:</PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Box borderWidth="0px" borderRadius="lg" p={6} mb={6}>
                          <Text
                            color={textColor}
                            fontSize="l"
                            fontWeight="bold"
                          >
                            Eigen opname uploaden (EXPERIMENTEEL)
                          </Text>
                          <Flex direction="column">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="bold"
                              mb="12px"
                            >
                              Audio/Video
                            </Text>
                            {uploadProgress == 0 && (
                              <Flex
                                align="center"
                                justify="center"
                                border="1px dashed #E2E8F0"
                                borderRadius="15px"
                                w="100%"
                                minH="130px"
                                cursor="pointer"
                                {...getRootProps()}
                                mb="16px"
                                bg="gray.100"
                                transition="background-color 0.2s"
                                flexDirection="column"
                                px={4}
                              >
                                <Input {...getInputProps()} />
                                <Image
                                  src={uploadImage}
                                  alt="Upload naar cloud"
                                  boxSize="200px"
                                  objectFit="contain"
                                  mb={4}
                                  fill="gray.400"
                                />
                                <Text
                                  color="gray.400"
                                  fontWeight="normal"
                                  textAlign="center"
                                >
                                  {selectedFile
                                    ? selectedFile.name
                                    : "Sleep een audio- of videobestand hier,\nof klik om er een te selecteren"}
                                </Text>
                              </Flex>
                            )}

                            {errorMessage && (
                              <Alert status="error" mb="16px" borderRadius="md">
                                <AlertIcon />
                                <AlertTitle mr={2}>Fout!</AlertTitle>
                                <AlertDescription>
                                  {errorMessage}
                                </AlertDescription>
                              </Alert>
                            )}
                            {/* Upload Progress Bar */}
                            {uploadProgress > 0 && !uploadComplete && (
                              <Flex align="center" mb="16px">
                                <Progress
                                  value={uploadProgress}
                                  size="sm"
                                  flex="1"
                                  mr="4"
                                  borderRadius="md"
                                  colorScheme="blue"
                                />
                                <Text fontSize="sm" color={textColor}>
                                  {uploadProgress}%
                                </Text>
                                {selectedFile && (
                                  <IconButton
                                    icon={<CloseIcon />}
                                    size="sm"
                                    variant="ghost"
                                    colorScheme="red"
                                    onClick={handleRemoveFile}
                                    aria-label="Verwijder bestand"
                                  />
                                )}
                              </Flex>
                            )}

                            {/* Live Transcription */}
                            {(conversation || currentSentence) && (
                              <Box mt="4">
                                <Text fontSize="md" fontWeight="bold" mb="2">
                                  Transcript:
                                </Text>
                                <Box
                                  borderWidth="1px"
                                  borderRadius="lg"
                                  p={4}
                                  mb={4}
                                  overflowY="auto"
                                  maxHeight="300px"
                                  bg="gray.50"
                                  ref={transcriptRef}
                                >
                                  <Text whiteSpace="pre-wrap">
                                    {conversation} {currentSentence}
                                  </Text>
                                </Box>
                              </Box>
                            )}

                            {uploadComplete && (
                              <>
                                <Alert
                                  status="success"
                                  mb="16px"
                                  borderRadius="md"
                                >
                                  <AlertIcon />
                                  <Box flex="1">
                                    <AlertTitle>Upload Voltooid!</AlertTitle>
                                    <AlertDescription>
                                      Uw bestand is succesvol geüpload.
                                    </AlertDescription>
                                  </Box>
                                </Alert>
                              </>
                            )}
                          </Flex>
                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </ButtonGroup>
            </Box>
          </HStack>
        </HStack>
      </HStack>
    </Flex>
  );
};

export default FileUploadCard;
