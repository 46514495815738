import jsPDF from "jspdf";

export class LawDiskPDFCreator {
    create(data, logoPath, callback) {
        const { summary, transcript, date, tags, isSummaryOnly } = data; // Voeg 'isSummaryOnly' toe aan de data object
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        let yOffset = 20; // Starting y position
        const marginLeft = 20;

        // Laad het logo dynamisch
        const image = new Image();
        image.src = logoPath; // Het pad naar het logo

        // Wacht tot de afbeelding is geladen en voeg deze toe aan het PDF-document
        image.onload = () => {
            // Voeg het logo toe aan de eerste pagina en daarna aan elke nieuwe pagina
            this.addLogoToPage(doc, image, pageWidth, pageHeight);

            // Title: "LawDisk Notulen"
            doc.setFont("helvetica", "bold");
            doc.setFontSize(24);
            doc.text("LawDisk Notulen", pageWidth / 2, yOffset, { align: "center" });
            yOffset += 20;

            // Subheading: "Van de vergadering op [date and time]"
            doc.setFont("helvetica", "normal");
            doc.setFontSize(12);
            doc.text(`Van de vergadering op ${date}`, pageWidth / 2, yOffset, { align: "center" });
            yOffset += 15;

            // Tags section
            doc.setFont("helvetica", "italic");
            doc.setFontSize(12);
            doc.text(`Tags: ${tags.join(", ")}`, marginLeft, yOffset);
            yOffset += 15;

            // Samenvatting Section
            yOffset = this.addSection(doc, "Samenvatting:", summary, yOffset, pageHeight, image, pageWidth, pageHeight);

            // Voeg transcriptie toe als isSummaryOnly false is
            if (!isSummaryOnly) {
                // Ensure the transcript starts on a new page or after enough space
                yOffset = this.checkPageBreak(doc, yOffset, pageHeight, 40, image, pageWidth, pageHeight); // Ensure enough space for transcript title

                // Transcriptie Section
                yOffset = this.addSection(doc, "Transcriptie:", transcript, yOffset, pageHeight, image, pageWidth, pageHeight);
            }

            // Roep de callback aan als de PDF klaar is (bijvoorbeeld om op te slaan of weer te geven)
            callback(doc);
        };
    }

    // Helper to add sections with proper line wrapping
    addSection(doc, title, content, yOffset, totalPageHeight, image, pageWidth, pageHeight) {
        const marginLeft = 20;

        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.text(title, marginLeft, yOffset);
        yOffset += 10;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        const lines = doc.splitTextToSize(content, 170); // Wrap text to fit width
        lines.forEach((line) => {
            if (yOffset > totalPageHeight - 20) {
                doc.addPage(); // Add a new page if content exceeds page height
                yOffset = 20;  // Reset yOffset to the top of the new page
                this.addLogoToPage(doc, image, pageWidth, totalPageHeight); // Voeg logo toe aan elke nieuwe pagina
            }
            doc.text(line, marginLeft, yOffset);
            yOffset += 7; // Adjust line spacing
        });
        return yOffset; // Return the updated yOffset
    }

    // Helper to check if a page break is needed
    checkPageBreak(doc, yOffset, totalPageHeight, requiredSpace, image, pageWidth, pageHeight) {
        if (yOffset + requiredSpace > totalPageHeight) {
            doc.addPage();
            yOffset = 20; // Reset yOffset for new page
            this.addLogoToPage(doc, image, pageWidth, totalPageHeight); // Voeg logo toe aan elke nieuwe pagina
        }
        return yOffset;
    }

    // Voeg het logo rechtsonder toe aan de pagina
    addLogoToPage(doc, image, pageWidth, pageHeight) {
        const logoWidth = 42; // 30% kleiner
        const logoHeight = (1132 / 4915) * logoWidth; // Maintain aspect ratio
        const xPosition = pageWidth - logoWidth - 10; // 10 punten van de rechterkant
        const yPosition = pageHeight - logoHeight - 10; // 10 punten van de onderkant
        doc.addImage(image, "PNG", xPosition, yPosition, logoWidth, logoHeight);
    }

    // Save the generated PDF with a specific filename
    savePDF(doc, formattedDate) {
        const documentName = `LawDisk_Notulen_${formattedDate}.pdf`;
        doc.save(documentName);
    }
}
