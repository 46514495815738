import React, { useEffect, useRef } from "react";

const CircularWaveform = () => {
  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaStreamRef = useRef(null);

  useEffect(() => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    audioContextRef.current = audioContext;
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    analyserRef.current = analyser;

    const getAudioStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaStreamRef.current = stream;
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        drawCircularWaveform();
      } catch (error) {
        console.error("Microphone access denied:", error);
        alert("Microphone access is required to use this feature.");
      }
    };

    getAudioStream();

    const resizeCanvas = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const size = Math.min(window.innerWidth, window.innerHeight) * 0.8;
      canvas.width = size;
      canvas.height = size;

      drawCircularWaveform();
    };

    const drawCircularWaveform = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = width / 4;

      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      ctx.clearRect(0, 0, width, height);
      ctx.strokeStyle = "rgb(96, 165, 250)";
      ctx.lineWidth = 2;

      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.beginPath();
      const slice = (Math.PI * 2) / bufferLength;

      for (let i = 0; i < bufferLength; i++) {
        const angle = slice * i;
        const amplitude = dataArray[i] / 128.0;
        const spikeLength = amplitude * (width / 20);

        const xStart = centerX + radius * Math.cos(angle);
        const yStart = centerY + radius * Math.sin(angle);
        const xEnd = centerX + (radius + spikeLength) * Math.cos(angle);
        const yEnd = centerY + (radius + spikeLength) * Math.sin(angle);

        ctx.moveTo(xStart, yStart);
        ctx.lineTo(xEnd, yEnd);
      }

      ctx.closePath();
      ctx.stroke();

      requestAnimationFrame(drawCircularWaveform);
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    return () => {
      // Cleanup: Stop the audio stream and close the audio context
      window.removeEventListener("resize", resizeCanvas);

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    };
  }, []);

  return <canvas ref={canvasRef} style={{ width: "100%", height: "auto" }} />;
};

export default CircularWaveform;
